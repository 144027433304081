import moment from 'moment';
import {
    FETCH_APPOINTMENTS, CREATE_APPOINTMENT, GET_APPOINTMENT, EDIT_APPOINTMENT, DELETE_APPOINTMENT,
    SUCCESS, ERROR
} from "./types";
import api from "../apis/api";
import history from '../history';

const formatAppointment = appointment => {
    appointment.date = new Date(appointment.date);
    return appointment;
}

const formatAppointmentsList = data => {
    data.forEach(appointment => {
        appointment = formatAppointment(appointment);
    });
    return data;
}

export const fetchAppointments = () => async dispatch => {
    fetchAppointmentsGeneric(dispatch, '/Appointments');
};

export const fetchAppointmentsByDateRange = (startDate, endDate) => async dispatch => {
    fetchAppointmentsGeneric(dispatch, `/Appointments/DateRange?StartDate=${startDate.toISOString()}&EndDate=${endDate.toISOString()}`);
};

export const fetchAppointmentsByDate = date => async dispatch => {
    fetchAppointmentsGeneric(dispatch, `/Appointments/Date?date=${date.toISOString()}`);
};

export const fetchAppointmentsByVehicleNumberPlate = numberPlate => async dispatch => {
    fetchAppointmentsGeneric(dispatch, `/Appointments/Vehicle?numberPlate=${numberPlate}`);
};

const fetchAppointmentsGeneric = async (dispatch, url) => {
    dispatch({ type: ERROR, payload: null })
    await api.get(url)
        .then(response => {
            dispatch({ type: FETCH_APPOINTMENTS, payload: formatAppointmentsList(response.data) });
        })
        .catch(() => {
            dispatch({ type: FETCH_APPOINTMENTS, payload: [] });
            dispatch({ type: ERROR, payload: 'appointment.fetchError' })
        });
}

export const createAppointment = formValues => async dispatch => {
    dispatch({ type: ERROR, payload: null })
    dispatch({ type: SUCCESS, payload: null })
    await api.post('/Appointments', formValues)
        .then(response => {
            dispatch({ type: CREATE_APPOINTMENT, payload: formatAppointment(response.data) });
            history.push(`/appointments/date/${moment(response.data.date).toDate().toLocaleDateString("fr-FR")}`);
            dispatch({ type: SUCCESS, payload: 'appointment.createSuccess' })
        })
        .catch(() => {
            dispatch({ type: ERROR, payload: 'appointment.createError' })
        });
};

export const getAppointment = id => async dispatch => {
    dispatch({ type: ERROR, payload: null })
    await api.get(`/Appointments/${id}`)
        .then(response => {
            dispatch({ type: GET_APPOINTMENT, payload: formatAppointment(response.data) });
        })
        .catch(() => {
            dispatch({ type: ERROR, payload: 'appointment.fetchError' })
        })
};

export const updateAppointment = formValues => async dispatch => {
    dispatch({ type: ERROR, payload: null })
    dispatch({ type: SUCCESS, payload: null })
    await api.put(`/Appointments`, formValues)
        .then(response => {
            dispatch({ type: EDIT_APPOINTMENT, payload: formatAppointment(response.data) });
            history.goBack();
            dispatch({ type: SUCCESS, payload: 'appointment.updateSuccess' })
        })
        .catch(() => {
            dispatch({ type: ERROR, payload: 'appointment.updateError' })
        })
};

export const deleteAppointment = id => async dispatch => {
    dispatch({ type: ERROR, payload: null })
    dispatch({ type: SUCCESS, payload: null })
    await api.delete(`/Appointments/${id}`)
        .then(() => {
            dispatch({ type: DELETE_APPOINTMENT, payload: id });
            dispatch({ type: SUCCESS, payload: 'appointment.deleteSuccess' })
        })
        .catch(() => {
            dispatch({ type: ERROR, payload: 'appointment.deleteError' })
        })
};