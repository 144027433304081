import React from 'react';
import {
    Route,
    Redirect
} from "react-router-dom";

const PrivateRoute = ({ ...params }) => {
    if (!sessionStorage.getItem('token')){
        return <Redirect to="/login" />
    }
    return (
        <Route {...params} />
    );
}

export default PrivateRoute;