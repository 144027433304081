import { CREATE_HOLIDAYS, GET_HOLIDAYS, DELETE_HOLIDAYS, ERROR, SUCCESS } from "./types";
import api from "../apis/api";

export const createHolidays = formValues => async dispatch => {
    dispatch({ type: ERROR, payload: null })
    dispatch({ type: SUCCESS, payload: null })
    await api.post('/holidays', formValues)
        .then(response => {
            dispatch({ type: CREATE_HOLIDAYS, payload: response.data });
            dispatch({ type: SUCCESS, payload: 'profile.createHolidaysSuccess' })
        })
        .catch(() => {
            dispatch({ type: ERROR, payload: 'profile.createHolidaysError' })
        });
};

export const fetchHolidays = () => async dispatch => {
    dispatch({ type: ERROR, payload: null });
    await api.get('/holidays/year')
        .then(response => {
            dispatch({ type: GET_HOLIDAYS, payload: response.data });
        })
        .catch(error => {
            if (error.response && error.response.status === 401) {
                dispatch({ type: ERROR, payload: 'login.expired' })
            }
        });
};

export const deleteHolidays = id => async dispatch => {
    dispatch({ type: ERROR, payload: null })
    dispatch({ type: SUCCESS, payload: null })
    await api.delete(`/holidays/${id}`)
        .then(() => {
            dispatch({ type: DELETE_HOLIDAYS, payload: id });
            dispatch({ type: SUCCESS, payload: 'profile.deleteHolidaysSuccess' })
        })
        .catch(() => {
            dispatch({ type: ERROR, payload: 'profile.deleteHolidaysError' })
        });
};