import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button, Typography, Grid, FormControl, NativeSelect, TextField, Icon, AppBar, Tabs, Tab, FormControlLabel, Switch, Chip } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import ModalGen from '../shared/ModalGen'
import './styles/ProfileModal.scss'
import { checkEmail, checkPassword, containsDigit, containsUpperCase, checkMinLength } from '../shared/utils'
import { updateAccount, updatePassword } from '../../actions/accountActions'
import { createHolidays, deleteHolidays } from '../../actions/holidaysActions'
import SharedDatePicker from '../shared/SharedDatePicker'

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const ProfileModal = ({ handleClose, open, account, holidays, updateAccount, updatePassword, createHolidays, deleteHolidays }) => {
    const { t } = useTranslation()

    const [changePassword, setChangePassword] = useState({ current: '', new: '', confirmation: '' })
    const [userProfile, setUserProfile] = useState({ displayName: '', email: '', emailConfirmation: '', language: account.language })
    const [holidaysForm, setHolidaysForm] = useState({ name: '', start: new Date(), end: null, hasEnd: false })
    const [tabIndex, setTabIndex] = React.useState(0);

    const renderDisplayName = () => {
        return (
            <Grid container direction="column">
                <Grid item>
                    <Typography variant="body1">{t('profile.displayName')}<span className="profile-modal-current-value">{account.displayName}</span></Typography>
                </Grid>
                <Grid item>
                    <TextField
                        label={t('profile.displayName')}
                        type="text"
                        value={userProfile.displayName}
                        onChange={(e) =>
                            setUserProfile({
                                displayName: e.target.value,
                                email: userProfile.email,
                                emailConfirmation: userProfile.emailConfirmation,
                                language: userProfile.language
                            })
                        }
                    />
                    {/* {renderSaveButton('save', displayName.new)} */}
                </Grid>
            </Grid>
        )
    }

    const renderEmail = () => {
        return (
            <Grid container direction="column">
                <Grid item>
                    <Typography variant="body1">{t('profile.email')}<span className="profile-modal-current-value">{account.email}</span></Typography>
                </Grid>
                <Grid item>
                    <TextField
                        className="profile-modal-email-textfield"
                        label={t('profile.newEmail')}
                        type="email"
                        value={userProfile.email}
                        onChange={(e) =>
                            setUserProfile({
                                displayName: userProfile.displayName,
                                email: e.target.value,
                                emailConfirmation: '',
                                language: userProfile.language
                            })
                        }
                        error={userProfile.email ? !checkEmail(userProfile.email) : null}
                    />
                    <TextField
                        className="profile-modal-email-textfield"
                        label={t('profile.confirmation')}
                        type="email"
                        value={userProfile.emailConfirmation}
                        disabled={!(userProfile.email && checkEmail(userProfile.email))}
                        onChange={(e) =>
                            setUserProfile({
                                displayName: userProfile.displayName,
                                email: userProfile.email,
                                emailConfirmation: e.target.value,
                                language: userProfile.language
                            })
                        }
                        error={userProfile.emailConfirmation ? userProfile.email !== userProfile.emailConfirmation : null}
                    />
                    {/* {renderSaveButton('save', userProfile.email && userProfile.email === userProfile.emailConfirmation)} */}
                </Grid>
            </Grid>
        )
    }

    const renderPasswordConstraint = (password, constraint, text) => {
        const passwordOk = constraint(password)
        return (
            <Typography variant="overline" className={`profile-password-contraint ${passwordOk ? 'valid' : 'invalid'}`}>
                <Icon>{passwordOk ? `check` : `close`}</Icon>
                {text}
            </Typography>
        )
    }

    const renderPassword = () => {
        return (
            <Grid container direction="column">
                <Grid item>
                    <Typography>{t('profile.password')}</Typography>
                </Grid>
                <Grid item>
                    <TextField
                        className="profile-modal-password-textfield"
                        label={t('profile.currentPassword')}
                        type="password"
                        value={changePassword.current}
                        onChange={(e) =>
                            setChangePassword({ current: e.target.value, new: changePassword.new, confirmation: changePassword.confirmation })
                        }
                    />
                </Grid>
                <Grid item>
                    <TextField
                        className="profile-modal-password-textfield"
                        label={t('profile.newPassword')}
                        type="password"
                        value={changePassword.new}
                        onChange={(e) =>
                            setChangePassword({ current: changePassword.current, new: e.target.value, confirmation: '' })
                        }
                        error={changePassword.new ? !checkPassword(changePassword.new) : null}
                    />
                </Grid>
                <Grid item className="profile-password-constraints-item">
                    {changePassword.new && <React.Fragment>
                        {renderPasswordConstraint(changePassword.new, containsDigit, '1 Digit')}
                        {renderPasswordConstraint(changePassword.new, containsUpperCase, '1 Uppercase')}
                        {renderPasswordConstraint(changePassword.new, checkMinLength, '8 characters')}
                    </React.Fragment>
                    }
                </Grid>
                <Grid item>
                    <TextField
                        className="profile-modal-password-textfield"
                        label={t('profile.confirmation')}
                        type="password"
                        value={changePassword.confirmation} onChange={(e) =>
                            setChangePassword({ current: changePassword.current, new: changePassword.new, confirmation: e.target.value })
                        }
                        disabled={!(changePassword.new && checkPassword(changePassword.new))}
                        error={changePassword.confirmation ? changePassword.new !== changePassword.confirmation : null}
                    />
                </Grid>
            </Grid>
        )
    }

    const renderLanguage = () => {
        return (
            <Grid container direction="row" className="profile-modal-language">
                <Grid item>
                    <Typography>{t('profile.language')}</Typography>
                </Grid>
                <Grid item>
                    <FormControl>
                        <NativeSelect
                            value={userProfile.language}
                            onChange={(e) =>
                                setUserProfile({
                                    displayName: userProfile.displayName,
                                    email: userProfile.email,
                                    emailConfirmation: userProfile.emailConfirmation,
                                    language: +e.target.value
                                })}
                        >
                            <option value={1}>{t('languages.english')}</option>
                            <option value={2}>{t('languages.french')}</option>
                        </NativeSelect>
                    </FormControl>
                </Grid>
            </Grid>
        )
    }

    const renderHolidays = () => {
        return (
            <Grid container direction="column" spacing={3}>
                <Grid item>
                    <Grid container direction="row" spacing={1} alignItems="flex-end">
                        <Grid item>
                            <TextField
                                className="profile-holidays-name"
                                label={t('profile.name')}
                                autoFocus
                                value={holidaysForm.name}
                                margin="normal"
                                onChange={({ target }) => setHolidaysForm({
                                    name: target.value,
                                    start: holidaysForm.start,
                                    end: holidaysForm.end,
                                    hasEnd: holidaysForm.hasEnd
                                })} />
                        </Grid>
                        <Grid item>
                            <SharedDatePicker
                                className="profile-holidays-datepicker"
                                label={t('profile.startDate')}
                                value={holidaysForm.start}
                                margin="normal"
                                dateformat="dd/MM/yyyy"
                                onChange={date => {
                                    setHolidaysForm({
                                        name: holidaysForm.name,
                                        start: date,
                                        end: holidaysForm.hasEnd ? moment(date).add(1, 'day') : null,
                                        hasEnd: holidaysForm.hasEnd
                                    });
                                }
                                }
                            />
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                value={holidaysForm.hasEnd}
                                control={<Switch
                                    className="profile-holidays-switch"
                                    onChange={() => setHolidaysForm({
                                        name: holidaysForm.name,
                                        start: holidaysForm.start,
                                        end: holidaysForm.hasEnd ? null : moment(holidaysForm.start).add(1, 'day') ,
                                        hasEnd: !holidaysForm.hasEnd
                                    })} />}
                                label={t('profile.to')}
                                labelPlacement="top"
                            />
                        </Grid>
                        <Grid item>
                            <SharedDatePicker
                                className="profile-holidays-datepicker"
                                value={holidaysForm.end}
                                margin="normal"
                                label={t('profile.endDate')}
                                dateformat="dd/MM/yyyy"
                                onChange={date => {
                                    setHolidaysForm({
                                        name: holidaysForm.name,
                                        start: holidaysForm.start,
                                        end: date,
                                        hasEnd: holidaysForm.hasEnd
                                    });
                                }}
                                disabled={!holidaysForm.hasEnd}
                            />
                        </Grid>
                        <Grid item>
                            <Icon className={`profile-holidays-add ${holidaysForm.name ? '' : 'disabled'}`}
                                onClick={() => {
                                    if (holidaysForm.name) {
                                        createHolidays({
                                            name: holidaysForm.name,
                                            start: holidaysForm.start,
                                            end: holidaysForm.end
                                        })
                                        setHolidaysForm({
                                            name: '',
                                            start: holidaysForm.start,
                                            end: null,
                                            hasEnd: holidaysForm.hasEnd
                                        })
                                    }
                                }} >add</Icon>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    {holidays && holidays.map(holiday => {
                        return (
                            <Chip key={holiday.id} className="profile-modal-holidays-item"
                                // size="small"
                                label={`${moment(holiday.start).format('DD MMM YYYY')} ${holiday.end ? `- ${moment(holiday.end).format('DD MMM YYYY')}` : ''} (${holiday.name})`}
                                onDelete={() => deleteHolidays(holiday.id)}
                            />
                        )
                    })}
                </Grid>
            </Grid>
        )
    }

    const handleTabsChange = (event, newValue) => {
        if (tabIndex === 0) {
            setUserProfile({
                displayName: '',
                email: '',
                emailConfirmation: '',
                language: account.language
            })
        } else if (tabIndex === 1) {
            setChangePassword({ current: '', new: '', confirmation: '' })
        }
        setTabIndex(newValue);
    };

    const renderContent = () => {
        return (
            <React.Fragment>
                <AppBar className="profile-modal-appbar-header" position="static" color="default">
                    <Tabs
                        value={tabIndex}
                        onChange={handleTabsChange}
                        variant="scrollable"
                        scrollButtons="on"
                    >
                        <Tab label={<React.Fragment><Icon>account_box</Icon> {t('profile.preferences')}</React.Fragment>} {...a11yProps(0)} />
                        <Tab label={<React.Fragment><Icon>lock</Icon> {t('profile.security')}</React.Fragment>} {...a11yProps(1)} />
                        <Tab label={<React.Fragment><Icon>flight</Icon> {t('profile.holidays')}</React.Fragment>} {...a11yProps(1)} />
                    </Tabs>
                </AppBar>
                <Grid container direction="column" className="profile-modal-content">
                    {tabIndex === 0 && <Grid container direction="column" className="profile-modal-content">
                        <Grid item className="profile-modal-content-item">
                            {renderDisplayName()}
                        </Grid>
                        <Grid item className="profile-modal-content-item">
                            {renderEmail()}
                        </Grid>
                        <Grid item className="profile-modal-content-item">
                            {renderLanguage()}
                        </Grid>
                    </Grid>}
                    {tabIndex === 1 &&
                        <Grid item className="profile-modal-content-item">
                            {renderPassword()}
                        </Grid>
                    }
                    {tabIndex === 2 &&
                        <Grid item>
                            {renderHolidays()}
                        </Grid>
                    }
                </Grid>
            </React.Fragment>
        )
    }

    const hasDisplayNameChangedAndValid = () => {
        return userProfile.displayName && userProfile.displayName.length >= 3
    }
    const hasEmailChangedAndValid = () => {
        return userProfile.email && userProfile.email === userProfile.emailConfirmation
    }

    const hasProfileChanges = () => {
        return hasDisplayNameChangedAndValid() || hasEmailChangedAndValid() || (userProfile.language && userProfile.language !== account.language)
    }

    const IsPasswordConfirmed = () => {
        return changePassword.current && changePassword.new && changePassword.new === changePassword.confirmation && checkPassword(changePassword.new)
    }

    const IsReadyToSubmitChange = () => {
        switch (tabIndex) {
            case 0:
                return hasProfileChanges()
            case 1:
                return IsPasswordConfirmed()
            default:
                return false
        }
    }

    return (
        <ModalGen
            handleClose={handleClose}
            open={open}
            header={<Typography variant="h6"><Icon>settings</Icon>{t('profile.modalTitle')}</Typography>}
            content={renderContent()}
            actions={
                <React.Fragment>
                    <Button className="profile-modal-save-button" 
                    disabled={!IsReadyToSubmitChange()} onClick={() => {
                        if (tabIndex === 0) {
                            setUserProfile({
                                displayName: '',
                                email: '',
                                emailConfirmation: '',
                                language: account.language
                            })
                            const { displayName, email, language } = userProfile
                            updateAccount({
                                displayName: hasDisplayNameChangedAndValid() ? displayName : account.displayName,
                                email: hasEmailChangedAndValid() ? email : account.email,
                                language: language !== account.language ? language : account.language,
                            })
                        } else if (tabIndex === 1) {
                            updatePassword({
                                oldPassword: changePassword.current,
                                newPassword: changePassword.confirmation
                            })
                            setChangePassword({
                                current: '',
                                new: '',
                                confirmation: ''
                            })
                        }
                        handleClose()
                    }} >{t('save')}</Button>
                    <Button onClick={() => {
                        setUserProfile({
                            displayName: '',
                            email: '',
                            emailConfirmation: '',
                            language: account.language
                        })
                        handleClose()
                    }} >{t('close')}</Button>
                </React.Fragment>
            }
        />
    )
}
const mapStateToProps = ({ account, holidays }) => {
    return { account, holidays: Object.values(holidays) }
}

export default connect(mapStateToProps, { updateAccount, updatePassword, createHolidays, deleteHolidays })(ProfileModal)