import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchCustomers } from '../../actions/customerActions';
import { getAppointment, updateAppointment } from '../../actions/appointmentActions';
import _ from 'lodash';
import BackLink from '../shared/BackLink';
import AppointmentForm from './AppointmentForm';

const AppointmentEdit = ({ getAppointment, fetchCustomers, updateAppointment, customers, appointmentId, appointment }) => {

    useEffect(() => {
        fetchCustomers();
        getAppointment(appointmentId);
    }, [fetchCustomers,getAppointment, appointmentId]);

    return (
        <React.Fragment>
            <BackLink />
            <AppointmentForm
                onSubmit={formValues => updateAppointment(formValues)}
                customers={customers}
                initialValues={appointment}
            />
        </React.Fragment>
    );
}

const mapPropsToState = (state, ownProps) => {
    let appointment = state.appointments[ownProps.match.params.id];
    if(appointment) {
        let customer = state.customers[appointment.customerId];
        if(customer){
            _.set(appointment, 'customerId', {value: appointment.customerId, label: `${customer.lastName.toUpperCase()} ${customer.firstName}`});
        }
    }

    return { customers: Object.values(state.customers), appointment: state.appointments[ownProps.match.params.id], appointmentId: ownProps.match.params.id }
}

export default connect(mapPropsToState, { getAppointment, updateAppointment, fetchCustomers })(AppointmentEdit);