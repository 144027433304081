import React from 'react';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { Grid, InputAdornment, Icon, IconButton, Button, Container, Typography } from '@material-ui/core';
import { withTranslation } from 'react-i18next';

import { renderTextField } from '../shared/FormUtils';

import './styles/CustomerForm.scss';

class CustomerForm extends React.Component {

    getTranslation = termId => {
        return this.props.t(`customer.form.${termId}`);
    }

    renderPhonesList = ({ fields }) => {
        if (fields.length === 0) {
            fields.push('');
        }
        return (
            <Grid container className="customer-form-phones" direction="column">
                <Grid item className="customer-form-phones-title">
                    <h4>{this.getTranslation('phones')}</h4>
                    <IconButton size="small" className="form-add-button" onClick={() => fields.push('')}>
                        <Icon fontSize="small" >add</Icon>
                    </IconButton>
                </Grid>
                <Grid container>
                    {fields.map((phone, index) => (
                        <Grid item key={`Phone ${index}`} className="customer-form-phone">
                            <Field className="form-input-width-100"
                                name={`${phone}`} component={renderTextField}
                                id={`Phone ${index}`}
                                label={`${this.getTranslation('phone')} ${index + 1}`}
                                margin="none"
                                fullWidth="false"
                            />
                            <IconButton size="small" className="form-del-button" onClick={() => fields.splice(index, 1)} disabled={fields.length === 1 && index === 0}>
                                <Icon fontSize="small" >clear</Icon>
                            </IconButton>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        );
    }

    renderVehiclesList = ({ fields }) => {
        return (
            <Grid container className="customer-form-vehicles" direction="column">
                <Grid item className="customer-form-vehicles-title">
                    <h3>{this.getTranslation('vehicles')}</h3>
                    <IconButton size="small" className="form-add-button"
                        onClick={() => fields.push({ brand: '', model: '', year: '', numberPlate: '', distance: '' })}>
                        <Icon fontSize="small" >add</Icon>
                    </IconButton>
                </Grid>
                {fields.map((vehicle, index) =>
                     (
                        <Grid container key={`vehicle-${index}`} className="customer-form-vehicle">
                            <Container>
                                <Field className="form-input-margin-right"
                                    name={`${vehicle}.brand`} component={renderTextField}
                                    label={this.getTranslation('brand')}
                                    margin="none"
                                />
                                <Field className="form-input-margin-right"
                                    name={`${vehicle}.model`} component={renderTextField}
                                    label={this.getTranslation('model')}
                                    margin="none"
                                />
                                <Field className="form-input-margin-right form-input-width-50"
                                    name={`${vehicle}.year`} component={renderTextField}
                                    label={this.getTranslation('year')}
                                    margin="none"
                                />
                                <Field className="form-input-margin-right form-input-width-100"
                                    name={`${vehicle}.numberPlate`} component={renderTextField}
                                    label={this.getTranslation('numberPlate')}
                                    margin="none"
                                />
                                <Field className="form-input-margin-right form-input-width-150"
                                    name={`${vehicle}.distance`} component={renderTextField}
                                    label={this.getTranslation('distance')}
                                    margin="none"
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="start">Km</InputAdornment>,
                                    }}
                                />
                                <IconButton size="small" className="form-del-button" onClick={() => fields.splice(index, 1)}>
                                    <Icon fontSize="small" >clear</Icon>
                                </IconButton>
                            </Container>
                        </Grid>
                    )
                )}
            </Grid>
        );
    }

    submitForm = formValues => {
        this.props.onSubmit(formValues);
    }

    render() {
        return (
            <form className="customer-form-component" onSubmit={this.props.handleSubmit(this.submitForm)} noValidate autoComplete="off">
                <Grid className="customer-form-informations" container direction="column" spacing={0}>
                    <h3>{this.getTranslation('generalInformation')}</h3>
                    <Grid item >
                        <Container>
                            <Field name="lastName" component={renderTextField}
                                id="lastname"
                                label={this.getTranslation('lastname')}
                                margin="none"
                                className="form-input-width-250 form-input-margin-right"
                            />
                            <Field name="firstName" component={renderTextField}
                                id="firstName"
                                label={this.getTranslation('firstname')}
                                margin="none"
                                className="form-input-width-250 form-input-margin-right"
                            />
                        </Container>
                        <Container >
                            <Field name="address.street" component={renderTextField}
                                className="customer-form-street"
                                id="address"
                                label={this.getTranslation('address')}
                                margin="none"
                            />
                        </Container>
                        <Container>
                            <Field name="address.zipCode" component={renderTextField}
                                className="form-input-width-100 form-input-margin-right"
                                id="zipCode"
                                label={this.getTranslation('zipCode')}
                                margin="none"
                            />
                            <Field name="address.city" component={renderTextField}
                                className="customer-form-city"
                                id="city"
                                label={this.getTranslation('city')}
                                margin="none"
                            />
                        </Container>
                    </Grid>
                    <h3>{this.getTranslation('contactInformation')}</h3>
                    <Grid item>
                        <Container>
                            <Field name="email" component={renderTextField}
                                className="customer-form-email"
                                id="email"
                                label="Email"
                                margin="none"
                            />
                            <FieldArray name="phones" component={this.renderPhonesList} />
                        </Container>
                    </Grid>

                    <Grid item>
                        <FieldArray name="vehicles" component={this.renderVehiclesList} />
                    </Grid>
                </Grid>
                <Container className="customer-form-action">
                    <Button className="customer-form-save" type="submit" disabled={!this.props.valid}>
                        <Icon fontSize="large">save</Icon>
                        <Typography variant="body1">{this.getTranslation('save')}</Typography>
                    </Button>
                </Container>
            </form>
        );
    }
}

const validate = formValues => {
    const errors = {};
    if (!formValues.lastName) {
        errors.lastName = 'Lastname is required.';
    }
    errors.phones = [];
    if (!formValues.phones || !formValues.phones[0] || formValues.phones[0] === '') {
        errors.phones[0] = 'At least one phone number is required.';
    }
    return errors;
}

export default reduxForm({
    form: 'customerForm',
    validate
})(withTranslation()(CustomerForm));