import React from 'react'
import { Modal, Backdrop, Fade, Paper, Grid } from '@material-ui/core'

import './styles/ModalGen.scss'

const ModalGen = ({ open, handleClose, actions, header, content }) => {
    return (
        <Modal aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="modal-gen"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}>
            <Fade in={open}>
                <Paper className="modal-gen-paper">
                    <Grid container direction="column">
                        <Grid item className="modal-gen-header">
                            {header}
                        </Grid>
                        <Grid item className="modal-gen-content">
                            {content}
                        </Grid>
                        <Grid item className="modal-gen-actions">
                            {actions}
                        </Grid>
                    </Grid>
                </Paper>
            </Fade>
        </Modal>
    )
}

export default ModalGen