import { GET_ACCOUNT_PROFILE, EDIT_ACCOUNT_PROFILE, ERROR, SUCCESS } from "./types"
import api from "../apis/api"
import i18n from "../i18n"
import { getLanguageNameFromId } from "../utils/languageUtils"
import moment from "moment"

export const getAccount = () => async dispatch => {
    await api.get(`/Account`)
        .then(({ data }) => {
            updateSessionProfile(data)
            dispatch({ type: GET_ACCOUNT_PROFILE, payload: data })
        })
        .catch(error => {
        })
}

export const updateAccount = formValues => async dispatch => {
    dispatch({ type: ERROR, payload: null })
    dispatch({ type: SUCCESS, payload: null })
    await api.put('/Account', formValues)
        .then(({ data }) => {
            updateSessionProfile(data)
            dispatch({ type: EDIT_ACCOUNT_PROFILE, payload: data });
            dispatch({ type: SUCCESS, payload: 'profile.successPreferences' })
        })
        .catch(() => {
            dispatch({ type: ERROR, payload: 'profile.errorPreferences' })
        });
};


export const updatePassword = formValues => async dispatch => {
    dispatch({ type: ERROR, payload: null })
    dispatch({ type: SUCCESS, payload: null })
    await api.put('/Account/password', formValues)
        .then(() => { 
            dispatch({ type: SUCCESS, payload: 'profile.successPassword' })
        })
        .catch(() => {
            dispatch({ type: ERROR, payload: 'profile.errorPassword' })
        });
};


const updateSessionProfile = data => {
    sessionStorage.removeItem('displayName')
    sessionStorage.removeItem('email')
    sessionStorage.removeItem('language')

    sessionStorage.setItem('displayName', data.displayName)
    sessionStorage.setItem('email', data.email)
    sessionStorage.setItem('language', data.language)

    const language = getLanguageNameFromId(+(data.language))
    i18n.changeLanguage(language)
    moment.locale(language === 'en' ? 'en-gb' : language)
}