import React from 'react';
import { connect } from 'react-redux';
import { fetchCustomers } from '../../actions/customerActions';
import { Divider, Grid, Typography, Icon } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import CustomerItem from './CustomerItem';

import './styles/CustomerList.scss';

const CustomerList = ({ customers }) => {
    const { t } = useTranslation();
    
    if (customers) {
        return (
            <Grid container className="customer-list-component" direction="column">
                {customers && customers.length === 0 &&
                    <Grid className="customer-list-noresult " container direction="column">
                        <Grid item>
                            <Icon>sentiment_dissatisfied</Icon>
                        </Grid>
                        <Grid item>
                            <Typography>{t('customer.noCustomer')}</Typography>
                        </Grid>
                    </Grid>
                }
                {customers && customers.map((customer) => {
                    return (
                        <Grid key={customer.id} item className="customer-list-item">
                            <CustomerItem {...customer} />
                            <Divider />
                        </Grid>
                    );
                })
                }
            </Grid>
        );
    }
}

const mapStateToProps = ({ customers }) => {
    return { customers: Object.values(customers) };
}

export default connect(mapStateToProps, { fetchCustomers })(CustomerList);