import React from 'react';
import CalendarDay from './CalendarDay';
import CalendarWeek from './CalendarWeek';



const Calendar = ({ viewMode, date, onDeleteAppointment, onCalendarDayClick, isMobile}) => {

    if(viewMode.type === 'D') {
        return <CalendarDay date={date} onDeleteAppointment={onDeleteAppointment} isMobile={isMobile}/>
    }

    return <CalendarWeek date={date} 
        nbDaysPerWeek={viewMode.nbDaysPerWeek} 
        onDeleteAppointment={onDeleteAppointment}
        onCalendarDayClick={onCalendarDayClick} 
        />
};

export default Calendar;