import { ERROR, SUCCESS } from '../actions/types'

export default (state = {}, action) => {
    switch (action.type) {
        case SUCCESS:
            return { ...state, success: action.payload }
        case ERROR:
            return {...state, error: action.payload }
        default:
            return state
    }
}