import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { updateCustomer, getCustomer } from '../../actions/customerActions';
import CustomerForm from './CustomerForm';

import './styles/CustomerEdit.scss';
import BackLink from '../shared/BackLink';

const CustomerEdit = ({ customerId, customer, getCustomer, updateCustomer }) => {
    
    useEffect(() => {
        getCustomer(customerId)
    }, [getCustomer, customerId]);

    return (
        <React.Fragment>
            <BackLink />
            <CustomerForm initialValues={customer} onSubmit={updateCustomer} />
        </React.Fragment>
    );
}

const mapStateToProps = (state, ownProps) => {
    return { customer: state.customers[ownProps.match.params.id], customerId: ownProps.match.params.id };
}

export default connect(mapStateToProps, { updateCustomer, getCustomer })(CustomerEdit);