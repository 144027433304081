import { combineReducers } from 'redux'
import { reducer as formReducer} from 'redux-form'
import customerReducer from './customerReducer'
import appointmentReducer from './appointmentReducer'
import authReducer from './authReducer'
import requestResultReducer from './requestResultReducer'
import accountReducer from './accountReducer'
import holidaysReducer from './holidaysReducer'

export default combineReducers({
    auth: authReducer,
    account: accountReducer,
    customers: customerReducer,
    appointments: appointmentReducer,
    holidays: holidaysReducer,
    requestResult: requestResultReducer,
    form: formReducer
})