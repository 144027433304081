import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Grid, Typography, Badge } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { fetchAppointmentsByDate } from '../../actions/appointmentActions';
import CalendarDayItem from './CalendarDayItem';
import { isAHoliday } from '../../utils/holidaysUtils';


import './styles/CalendarDay.scss';


const CalendarDay = ({ appointments, date, holidays, fetchAppointmentsByDate, onDeleteAppointment, isMobile }) => {
    const { t } = useTranslation();
    useEffect(() => {
        fetchAppointmentsByDate(moment(date));
    }, [fetchAppointmentsByDate, date])

    appointments.sort(function (a, b) {
        return new Date(a.date) - new Date(b.date);
    });

    return (
        <Grid container direction="column">
            <Grid item className="calendar-day-title">
                <Typography variant="h4">{moment(date).format('dddd D MMMM YYYY')} {isAHoliday(holidays, date) ? `(${t('appointment.holidays')})` : ''}</Typography>
            </Grid>
            <Grid item className="calendar-day-subtitle">
                <Badge badgeContent={appointments.length ? appointments.length : '0'} anchorOrigin={{ vertical: 'top', horizontal: 'left' }}>
                    <Typography variant="h5">{t('appointment.appointments')}</Typography>
                </Badge>
            </Grid>
            <Grid item className="calendar-day-content">
                {appointments.map(appointment => {
                    return <CalendarDayItem key={appointment.id} appointment={appointment} onDeleteAppointment={onDeleteAppointment} isMobile={isMobile}/>
                })}
            </Grid>
        </Grid>
    );
};

const mapPropsToState = state => {
    return { appointments: Object.values(state.appointments), holidays: Object.values(state.holidays) }
}

export default connect(mapPropsToState, { fetchAppointmentsByDate })(CalendarDay);

