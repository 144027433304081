import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import 'moment/locale/fr'
import 'moment/locale/en-gb'

import './index.css'

import App from './components/app/App'
import reducers from './reducers'

// import i18n (needs to be bundled )) 
import './i18n'
import moment from 'moment'
import { getLanguageNameFromId } from './utils/languageUtils'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(
    reducers,
    composeEnhancers(applyMiddleware(thunk))
)

const language = getLanguageNameFromId(+sessionStorage.getItem('language'))
moment.locale(language === 'en' ? 'en-gb' : language)

ReactDOM.render(
    <Provider store={store}>
        <Suspense fallback="loading">
            <App />
        </Suspense>
    </Provider>,
    document.querySelector('#root')
)