import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Grid, TextField, InputAdornment, Icon, Button, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import {signIn} from '../../actions/authActions'

const LoginForm = ({ signIn }) => {
    const { t } = useTranslation()
    const [formValues, setFormValues] = useState({ username: '', password: '', remember: false })

    const setEmailValue = value => {
        setFormValues({ username: value, password: formValues.password, remember: formValues.remember })
    }

    const setPasswordValue = value => {
        setFormValues({ username: formValues.username, password: value, remember: formValues.remember })
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            if(formValues.username && formValues.password){
                event.preventDefault()
                signIn(formValues)
            }
        }
    }

    return (
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <Typography variant="h4">{t('login.title')}</Typography>
            </Grid>
            <Grid item>
                <TextField
                    id="username"
                    label={t('login.email')}
                    value={formValues.username}
                    onChange={({ target }) => setEmailValue(target.value)}
                    margin="normal"
                    type="email"
                    autoComplete="email"
                    fullWidth
                    InputProps={{
                        startAdornment: <InputAdornment position="start">@</InputAdornment>,
                    }}
                />
            </Grid>
            <Grid item>
                <TextField
                    id="password"
                    label={t('login.password')}
                    value={formValues.password}
                    onChange={({ target }) => setPasswordValue(target.value)}
                    type="password"
                    autoComplete="current-password"
                    margin="normal"
                    fullWidth
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><Icon>lock</Icon></InputAdornment>,
                    }}
                    onKeyPress={(e) => handleKeyPress(e) }

                />
            </Grid>
            <Grid item>
                <Button variant="outlined" fullWidth disabled={!(formValues.username && formValues.password)}
                        onClick={() => signIn(formValues)}>
                    {t('login.buttonTitle')}
                </Button>
            </Grid>
        </Grid>
    )

}

export default connect(null, { signIn })(LoginForm)