import {
    FETCH_CUSTOMERS, CREATE_CUSTOMER, GET_CUSTOMER, EDIT_CUSTOMER, DELETE_CUSTOMER,
    SEARCH_CUSTOMER_LASTNAME, SEARCH_CUSTOMER_NUMBERPLATE,
    ERROR, SUCCESS
} from "./types";
import api from "../apis/api";
import history from '../history';

const routeToCustomers = '/customers'

export const searchCustomerByLastname = value => async dispatch => {
    dispatch({ type: ERROR, payload: null });
    await api.get(`/Customers/Lastname?value=${value}`)
        .then(response => {
            dispatch({ type: SEARCH_CUSTOMER_LASTNAME, payload: response.data });
            history.push(routeToCustomers);
        })
        .catch(() => {
        });
};

export const createCustomer = formValues => async dispatch => {
    dispatch({ type: ERROR, payload: null })
    dispatch({ type: SUCCESS, payload: null })
    await api.post('/Customers', formValues)
        .then(response => {
            dispatch({ type: CREATE_CUSTOMER, payload: response.data });
            history.push(routeToCustomers);
            dispatch({ type: SUCCESS, payload: 'customer.createSuccess' })
        })
        .catch(() => {
            dispatch({ type: ERROR, payload: 'customer.createError' })
        });
};

export const fetchCustomers = () => async dispatch => {
    dispatch({ type: ERROR, payload: null });
    await api.get('/Customers')
        .then(response => {
            dispatch({ type: FETCH_CUSTOMERS, payload: response.data });
        })
        .catch(error => {
            if (error.response && error.response.status === 401) {
                dispatch({ type: ERROR, payload: 'login.expired' })
            } else {
                dispatch({ type: ERROR, payload: 'customer.fetchError' })
            }
        });
};

export const getCustomer = id => async dispatch => {
    dispatch({ type: ERROR, payload: null });
    await api.get(`/Customers/${id}`)
        .then(response => {
            dispatch({ type: GET_CUSTOMER, payload: response.data });
        })
        .catch(() => { });
};

export const getCustomerByVehicleNumberPlate = numberPlate => async dispatch => {
    dispatch({ type: ERROR, payload: null });
    await api.get(`/Customers/Vehicle?numberPlate=${numberPlate}`)
        .then(response => {
            return dispatch({ type: SEARCH_CUSTOMER_NUMBERPLATE, payload: response.data });
        })
        .catch(() => {
            dispatch({ type: SEARCH_CUSTOMER_NUMBERPLATE, payload: [] });
        });
};

export const updateCustomer = formValues => async dispatch => {
    dispatch({ type: ERROR, payload: null })
    dispatch({ type: SUCCESS, payload: null })
    await api.put('/Customers', formValues)
        .then(response => {
            dispatch({ type: EDIT_CUSTOMER, payload: response.data });
            history.push(routeToCustomers);
            dispatch({ type: SUCCESS, payload: 'customer.updateSuccess' })
        })
        .catch(() => {
            dispatch({ type: ERROR, payload: 'customer.updateError' })
        });
};

export const deleteCustomer = id => async dispatch => {
    dispatch({ type: ERROR, payload: null })
    dispatch({ type: SUCCESS, payload: null })
    await api.delete(`/Customers/${id}`)
        .then(() => {
            dispatch({ type: DELETE_CUSTOMER, payload: id });
            history.push(routeToCustomers);
            dispatch({ type: SUCCESS, payload: 'customer.deleteSuccess' })
        })
        .catch(() => {
            dispatch({ type: ERROR, payload: 'customer.deleteError' })
        });
};