import React from 'react';
import { Typography, Popper, Paper, Grid, Divider } from '@material-ui/core';

import './styles/CalendarWeekItemDetails.scss';

const CalendarWeekItemDetails = ({ open, anchorEl, title, content, actions }) => {
    return (
        <Popper className="calendar-week-item-details" open={open ? open : false} anchorEl={anchorEl} transition
            modifiers={{
                flip: {
                    enabled: true,
                },
                preventOverflow: {
                    enabled: true,
                    boundariesElement: 'scrollParent',
                },
            }}>
            <Paper className="calendar-week-item-details-paper">
                <Grid container direction="column">
                    <Grid container className="calendar-week-item-details-title" direction="row">
                        <Typography>{title}</Typography>
                    </Grid>
                    <Divider />
                    <Grid container className="calendar-week-item-details-content" direction="row">
                        {content}
                    </Grid>
                    <Divider />
                    <Grid container className="calendar-week-item-details-actions" direction="row">
                        {actions}
                    </Grid>
                </Grid>
            </Paper>
        </Popper>
    );
};

export default CalendarWeekItemDetails;