import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Grid, Button, Icon, Typography, Fab, FormControl, NativeSelect } from '@material-ui/core';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import './styles/Appointment.scss'
import Header from '../shared/Header';
import Main from '../shared/Main';
import SharedDatePicker from '../shared/SharedDatePicker';
import Calendar from './Calendar';
import { fetchCustomers } from '../../actions/customerActions';
import history from '../../history';
import AppointmentDeleteModal from './AppointmentDeleteModal';


const navigateToDate = date => {
    history.push(`/appointments/date/${date.toLocaleDateString("fr-FR")}`);
}

const Appointment = ({ fetchCustomers }) => {
    const { t } = useTranslation();
    let { day, month, year } = useParams();
    const [selectedDate, setSelectedDate] = useState(day ? new Date(year, +month - 1, day) : new Date());
    const [viewMode, setViewMode] = useState({ type: 'W', nbDaysPerWeek: 5 });
    const [appointmentDeleteModal, setAppointmentDeleteModal] = useState({ open: false, appointmentId: null })
    const isMobile = window.screen.width <= 600

    useEffect(() => {
        fetchCustomers();
    }, [fetchCustomers])

    return (
        <React.Fragment>
            <Main header={
                <Header className="appointment-component-header" titleLinkTo='/appointments' titleLinkClick={undefined} titleLinkContent={t('appointment.title')}
                    searchField={
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <Grid container>
                                    <Grid item>
                                        <Fab size="small" className="appointment-search-week-button"
                                            onClick={() => {
                                                let date = moment(selectedDate).subtract(1, viewMode.type === 'W' ? 'weeks' : 'days').toDate();
                                                navigateToDate(date);
                                                setSelectedDate(date);
                                            }}
                                        >
                                            <Icon>navigate_before</Icon>
                                        </Fab>
                                    </Grid>
                                    <Grid item className="appointment-search-field">
                                        <SharedDatePicker value={selectedDate}
                                            dateformat="dd/MM/yyyy"
                                            onChange={date => {
                                                navigateToDate(date);
                                                setSelectedDate(date);
                                            }
                                            }
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Fab size="small" className="appointment-search-week-button"
                                            onClick={() => {
                                                let date = moment(selectedDate).add(1, viewMode.type === 'W' ? 'weeks' : 'days').toDate();
                                                navigateToDate(date);
                                                setSelectedDate(date);
                                            }}
                                        >
                                            <Icon>navigate_next</Icon>
                                        </Fab>

                                    </Grid>
                                    <Grid item className="appointment-search-week-number">
                                        <Typography variant="overline" >{`${isMobile ? t('appointment.week').substr(0, 1) : t('appointment.week')}`} {moment(selectedDate).format('w')}</Typography>
                                    </Grid>
                                    <Grid item className="appointment-search-today-button">
                                        <Button onClick={() => {
                                            let date = new Date();
                                            navigateToDate(date);
                                            setSelectedDate(date);
                                        }
                                        }>
                                            <Icon>today</Icon>
                                            <Typography variant="body2">{isMobile ? t('appointment.today').length > 5 ? `${t('appointment.today').substr(0, 3)}.` : t('appointment.today') : t('appointment.today')}</Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Grid container>
                                    <Grid item className="appointment-view-mode-type">
                                        {!isMobile && <Typography>{t('appointment.viewMode')}</Typography>}
                                        <FormControl>
                                            <NativeSelect
                                                value={viewMode.type}
                                                onChange={(e) => setViewMode({ type: e.target.value, nbDaysPerWeek: viewMode.nbDaysPerWeek })}
                                            >
                                                <option value={'D'}>{t('appointment.day')}</option>
                                                <option value={'W'}>{t('appointment.week')}</option>
                                            </NativeSelect>
                                        </FormControl>
                                    </Grid>
                                    {viewMode.type === 'W' && <Grid item className="appointment-view-mode-nb-days">
                                        {!isMobile && <Typography>{t('appointment.nbDayPerWeek')}</Typography>}
                                        <FormControl>
                                            <NativeSelect
                                                value={viewMode.nbDaysPerWeek}
                                                onChange={(e) => setViewMode({ type: viewMode.type, nbDaysPerWeek: +e.target.value })}
                                            >
                                                <option value={5}>5 {t('appointment.days')}</option>
                                                <option value={6}>6 {t('appointment.days')}</option>
                                                <option value={7}>7 {t('appointment.days')}</option>
                                            </NativeSelect>
                                        </FormControl>
                                    </Grid>}
                                </Grid>
                            </Grid>
                        </Grid>}
                    hasAction={true} actionLinkTo={`/appointments/create/from/${moment(new Date(selectedDate).setHours(8, 0, 0, 0)).toISOString()}`} actionTitle={t('appointment.addAnAppointment')} actionLinkIcon='add'
                />}
            >
                <Calendar viewMode={viewMode} date={selectedDate}
                    onDeleteAppointment={(appointmentId) => setAppointmentDeleteModal({ open: true, appointmentId: appointmentId })}
                    onCalendarDayClick={(date) => {
                        setViewMode({ type: 'D', nbDaysPerWeek: viewMode.nbDaysPerWeek });
                        setSelectedDate(date);
                        navigateToDate(date);
                    }}
                    isMobile={isMobile} />
            </Main>
            <AppointmentDeleteModal open={appointmentDeleteModal.open}
                handleClose={() => setAppointmentDeleteModal({ open: false })}
                appointmentId={appointmentDeleteModal.appointmentId} />
        </React.Fragment>
    );
}

const mapStateToProps = state => {
    return { appointments: Object.values(state.appointments) }
}

export default connect(mapStateToProps, { fetchCustomers })(Appointment);
