import auth from "../apis/auth"
import history from '../history'
import { SIGNIN, SIGNOUT, ERROR } from "./types"

const encodeForm = (data) => {
    let formData = new FormData()
    Object.keys(data)
        .map(key => formData.append(key, data[key]))
    return formData
}

export const signIn = formValues => async dispatch => {
    dispatch({ type: ERROR, payload: null })
    formValues.client_id = 'carrepaircenter'
    formValues.client_secret = 'carrepaircenter_secret'
    formValues.grant_type = 'password'
    formValues.scope = 'carrepaircenter_api openid profile email offline_access'
    await auth.post('/connect/token', encodeForm(formValues))
        .then(response => {
            sessionStorage.setItem('token', response.data.access_token)
            sessionStorage.setItem('refresh_token', response.data.refresh_token)
            dispatch({ type: SIGNIN, payload: response.data })
            history.push(`/appointments/date/${new Date().toLocaleDateString("fr-FR")}`)
        })
        .catch(() => {
            dispatch({ type: ERROR, payload: "login.error" })
        })
}

export const signOut = () => async dispatch => {
    sessionStorage.clear()
    dispatch({ type: SIGNOUT, payload: null })
    history.push('/login')
}

