import React from 'react';
import { DatePicker } from '@material-ui/pickers';

const SharedDatePicker = props => {
    return (
        <DatePicker
            disableToolbar
            variant="inline"
            format={props.dateformat}
            autoOk
            ampm={false}
            {...props}
        />
    );
}

export default SharedDatePicker;