import {
        CREATE_CUSTOMER,
        DELETE_CUSTOMER,
        EDIT_CUSTOMER,
        GET_CUSTOMER,
        FETCH_CUSTOMERS,
        SEARCH_CUSTOMER_LASTNAME,
        SEARCH_CUSTOMER_NUMBERPLATE
} from '../actions/types'

import _ from 'lodash'

export default (state = {}, { type, payload }) => {
        switch (type) {
                case FETCH_CUSTOMERS:
                        return { ..._.mapKeys(payload, 'id') }
                case SEARCH_CUSTOMER_LASTNAME:
                        return { ..._.mapKeys(payload, 'id') }
                case SEARCH_CUSTOMER_NUMBERPLATE:
                        return payload ? { [payload.id]: payload } : {}
                case CREATE_CUSTOMER:
                        return { ...state, [payload.id]: payload }
                case DELETE_CUSTOMER:
                        return _.omit(state, payload)
                case EDIT_CUSTOMER:
                        return { ...state, [payload.id]: payload }
                case GET_CUSTOMER:
                        return { ...state, [payload.id]: payload }
                default:
                        return state
        }
}