import React from 'react';
import { Typography, Grid, Icon } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import './styles/VehicleInfos.css';
import NumberPlate from '../shared/NumberPlate';

const VehicleInfos = ({ vehicle }) => {
    const { t } = useTranslation();

    return (
        <Grid container
            className="vehicle-infos"
            direction="column">
            <Grid item className="vehicle-item-title">
                <Icon>directions_car</Icon>
                <Typography variant="h6">{t('vehicle.vehicle')}</Typography>
            </Grid>
            <Grid container direction="column" className="vehicle-item-content">
                {vehicle &&
                    <React.Fragment>
                        <Grid item>
                            <Typography variant="body1">
                                <NumberPlate numberPlate={vehicle.numberPlate} />
                            </Typography>
                        </Grid>
                        {vehicle.oldNumberPlate &&
                            <Grid item>
                                <Typography variant="body1">
                                    <span style={{ marginRight: '5px' }}>{t('vehicle.old')}:</span>
                                    <NumberPlate numberPlate={vehicle.oldNumberPlate} />
                                </Typography>
                            </Grid>
                        }
                        <Grid item>
                            <Typography variant="body1">{vehicle.brand} {vehicle.model}</Typography>
                        </Grid>
                        <Grid item className="">
                            <Typography variant="body1">{t('vehicle.year')}: {vehicle.year}</Typography>
                        </Grid>
                        <Grid item className="">
                            <Typography variant="body1">{vehicle.distance} Km</Typography>
                        </Grid>
                    </React.Fragment>
                }
            </Grid>
        </Grid>
    );
};

export default VehicleInfos;