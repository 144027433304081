import {
    CREATE_HOLIDAYS,
    DELETE_HOLIDAYS,
    GET_HOLIDAYS
} from '../actions/types'

import _ from 'lodash'

export default (state = {}, { type, payload }) => {
    switch (type) {
            case GET_HOLIDAYS:
                    return { ..._.mapKeys(payload, 'id') }
            case CREATE_HOLIDAYS:
                    return { ...state, [payload.id]: payload }
            case DELETE_HOLIDAYS:
                    return _.omit(state, payload)
            default:
                    return state
    }
}