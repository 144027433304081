import {
    CREATE_APPOINTMENT,
    DELETE_APPOINTMENT,
    EDIT_APPOINTMENT,
    GET_APPOINTMENT,
    FETCH_APPOINTMENTS
} from '../actions/types'

import _ from 'lodash'

export default (state = {}, action) => {
    switch (action.type) {
        case FETCH_APPOINTMENTS:
            return { ..._.mapKeys(action.payload, 'id') }
        case CREATE_APPOINTMENT:
            return { ...state, [action.payload.id]: action.payload }
        case DELETE_APPOINTMENT:
            return _.omit(state, action.payload)
        case EDIT_APPOINTMENT:
            return { ...state, [action.payload.id]: action.payload }
        case GET_APPOINTMENT:
            return { ...state, [action.payload.id]: action.payload }
        default:
            return state
    }
}