import React, { useState } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { Button, Icon, Grid, Avatar, Drawer, Container, Divider, Typography } from '@material-ui/core'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import './styles/NavigationBar.scss'
import { signOut } from '../../actions/authActions'
import ProfileModal from './ProfileModal'

const NavigationBar = ({ signOut, account }) => {
    const { t } = useTranslation()
    const [profileModal, setProfileModal] = useState({ open: false })
    const { displayName } = account

    const isMobile = window.screen.width <= 600

    const avatarText = displayName && displayName.split(/\s/).reduce((response, word) => response += word.slice(0, 1), '').toUpperCase()

    const renderMobileNavBar = () => {
        return (
            <Grid container direction="row">
                <Grid item xs={3}>
                    <Container className="navigation-bar-profile">
                        <Avatar>{avatarText}</Avatar>
                        <Typography variant="overline" display="block">
                            <span className="navigation-bar-profile-display-name">{displayName}</span>
                            <Icon className="navigation-bar-profile-settings" onClick={() => setProfileModal({ open: true })}>
                                settings
                            </Icon>
                        </Typography>
                    </Container>
                </Grid>
                <Grid item xs={7}>
                    <Grid container className="navigation-bar-routes" direction="row" >
                        <Grid item xs={4}>
                            <NavLink activeClassName="active" to={`/appointments/date/${moment(new Date()).toDate().toLocaleDateString("fr-FR")}`}
                                isActive={(_, location) => location.pathname.startsWith('/appointments')}>
                                <Icon>today</Icon>
                            </NavLink>
                        </Grid>
                        <Grid item xs={4}>
                            <NavLink activeClassName="active" to="/customers">
                                <Icon>people</Icon>
                            </NavLink>
                        </Grid>
                        <Grid item xs={4}>
                            <NavLink activeClassName="active" to="/vehicles" >
                                <Icon>drive_eta</Icon>
                            </NavLink>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={2}>
                    <Button className="navigation-bar-logout-button" onClick={() => signOut()}>
                        <Icon>power_settings_new</Icon>
                    </Button>
                </Grid>

            </Grid>
        )
    }

    const renderNonMobileNavBar = () => {
        return (
            <React.Fragment>
                <Container className="navigation-bar-profile">
                    <Avatar>{avatarText}</Avatar>
                    <Typography variant="overline" display="block">
                        <span className="navigation-bar-profile-display-name">{displayName}</span>
                        <Icon className="navigation-bar-profile-settings"
                            title="Profile settings"
                            onClick={() => setProfileModal({ open: true })}
                        >
                            settings
                    </Icon>
                    </Typography>
                </Container>
                <Divider />
                <Grid container className="navigation-bar-routes"
                    direction="column"
                    alignItems="flex-end"
                >
                    <NavLink activeClassName="active" to={`/appointments/date/${moment(new Date()).toDate().toLocaleDateString("fr-FR")}`}
                        isActive={(_, location) => location.pathname.startsWith('/appointments')}>
                        <Icon>today</Icon>
                        <Typography variant="overline">{t('navigation.appointmentTitle')}</Typography>
                    </NavLink>
                    <NavLink activeClassName="active" to="/customers">
                        <Icon>people</Icon>
                        <Typography variant="overline">{t('navigation.customerTitle')}</Typography>
                    </NavLink>
                    <NavLink activeClassName="active" to="/vehicles" >
                        <Icon>drive_eta</Icon>
                        <Typography variant="overline">{t('navigation.vehicleTitle')}</Typography>
                    </NavLink>
                </Grid>
                <footer>
                    <Button onClick={() => signOut()}>
                        <Icon>power_settings_new</Icon>
                        <Typography variant="overline">{t('navigation.logout')}</Typography>
                    </Button>
                </footer>

            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Drawer className="navigation-bar-component" variant="permanent" anchor={`${isMobile ? 'bottom' : 'left'}`} >
                {isMobile ? renderMobileNavBar() : renderNonMobileNavBar()}
            </Drawer>
            {profileModal.open &&
                <ProfileModal
                    open={profileModal.open}
                    handleClose={() => setProfileModal({ open: false })}
                />
            }
        </React.Fragment>
    )
}

const mapStateToProps = ({ account }) => {
    return { account }
}

export default connect(mapStateToProps, { signOut })(NavigationBar)