import React from 'react';
import { Grid } from '@material-ui/core';

import './styles/Login.scss';
import LoginForm from './LoginForm';

const Login = () => {
    return (
            <Grid container >
                <Grid item className="login-form" xs={12}>
                    <Grid container justify="center" alignContent="center" >
                        <Grid item>
                            <LoginForm />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
    )
}

export default Login;