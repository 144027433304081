import React from 'react';
import { connect } from 'react-redux';
import { Field, FieldArray, reduxForm, formValueSelector, change } from 'redux-form';
import { Container, Grid, Button, Icon, IconButton, Typography, MenuItem, FormControl, InputLabel, InputAdornment } from '@material-ui/core';
import { withTranslation } from 'react-i18next';

import { renderDatePickerField, renderSelectField, renderTextField, renderTimePickerField, renderReactSelectField } from '../shared/FormUtils';

import './styles/AppointmentForm.scss';
import { getFormattedNumberPlate } from '../shared/utils';

class AppointmentForm extends React.Component {

    state = { selectedDate: this.props.initialValues ? this.props.initialValues.date : new Date() }

    getTranslation = termId => {
        return this.props.t(`appointment.form.${termId}`);
    }

    submitForm = formValues => {
        if (formValues.repairs) {
            formValues.repairs.forEach(repair => {
                repair.name = this.props.t('appointment.form.repairs', { returnObjects: true })[repair.id]
            });
        }
        formValues.customerId = formValues.customerId.value;
        this.props.onSubmit(formValues);
    }

    onCustomerChange = () => {
        this.props.dispatch(change('appointmentForm', 'vehicle', null));
    }

    renderVehicleItems = () => {
        if (this.props.customerId) {
            let custId = this.props.customerId.value;
            let cust = this.props.customers.filter(customer => customer.id === custId)[0];
            if (cust) {
                return cust.vehicles && cust.vehicles.map(vehicle => {
                    let isSelected = this.props.initialized && this.props.vehicle ?
                        this.props.dirty ?
                            this.props.vehicle && vehicle.numberPlate === this.props.vehicle.numberPlate
                            : vehicle.numberPlate === this.props.initialValues.vehicle.numberPlate
                        : false;
                    if (isSelected) {
                        vehicle = this.props.vehicle;
                    }
                    return <MenuItem
                        selected={isSelected}
                        key={vehicle.numberPlate} value={vehicle}>
                        {`[${getFormattedNumberPlate(vehicle.numberPlate)}] ${vehicle.brand.toUpperCase()} ${vehicle.model.toUpperCase()}`}
                    </MenuItem>
                })
            }

        }
    }

    renderRepairs = ({ fields }) => {
        return (
            <Grid containerdirection="column">
                <Grid item className="appointment-form-repairs-title">
                    <h3>{this.getTranslation('repairsTitle')}</h3>
                    <IconButton size="small" className="form-add-button"
                        onClick={() => fields.push({ id: 0, name: 'Unknown', comment: '' })}>
                        <Icon fontSize="small" >add</Icon>
                    </IconButton>
                </Grid>
                {fields.map((repair, index) => (
                    <Grid container key={`repair-${index}`}>
                        <Container>
                            <FormControl className="form-input-margin-right">
                                <InputLabel htmlFor={`${repair}.id`}>
                                    {this.getTranslation('repair')}
                                </InputLabel>
                                <Field name={`${repair}.id`} component={renderSelectField}
                                    id={`${repair}.id`}
                                    margin="none"
                                    className="form-input-width-250"
                                >
                                    {
                                        this.props.t('appointment.form.repairs', { returnObjects: true }).map((repair, index) => {
                                            return <MenuItem key={`repair-${index}`} value={index}>{repair}</MenuItem>;
                                        })
                                    }
                                </Field>
                            </FormControl>
                            <Field
                                name={`${repair}.comment`} component={renderTextField}
                                label={this.getTranslation('comment')}
                                margin="none"
                                className="form-input-width-250"
                            />
                            <IconButton size="small" className="form-del-button" onClick={() => fields.splice(index, 1)}>
                                <Icon fontSize="small" >clear</Icon>
                            </IconButton>
                        </Container>
                    </Grid>
                ))}
            </Grid>
        );
    }

    render() {
        return (
            <form className="appointment-form-component" onSubmit={this.props.handleSubmit(this.submitForm)} noValidate autoComplete="off">
                <Grid className="appointment-form-informations" container direction="column" spacing={0}>
                    <h3>{this.getTranslation('dateInformation')}</h3>
                    <Grid item >
                        <Field name="date" component={renderDatePickerField}
                            id="date"
                            label={this.getTranslation('date')}
                            margin="none"
                            normalize={() => this.state.selectedDate}
                            onChange={value => this.setState({ selectedDate: value })}
                            parse={() => this.state.selectedDate}
                            format={() => this.state.selectedDate}
                            date-format="dd/MM/yyyy"
                            dateformat="dd/MM/yyyy"
                            className="form-input-width-200 form-input-margin-right"
                        />
                        <Field name="date" component={renderTimePickerField}
                            id="date-time"
                            label={this.getTranslation('time')}
                            margin="none"
                            onTimeChange={value => this.props.dispatch(change('appointmentForm', 'date', value))}
                            value={this.state.selectedDate}
                            className="appointment-form-datetime form-input-width-200 form-input-margin-right"
                        />

                        <FormControl>
                            <InputLabel htmlFor="duration">
                                {this.getTranslation('duration')}
                            </InputLabel>
                            <Field name="duration" component={renderSelectField}
                                id="duration"
                                margin="none"
                                fullWidth="false"
                                className="form-input-width-150"
                                PaperProps={{
                                    style: {
                                        maxHeight: 415,
                                        width: 200,
                                    },
                                }}
                            >
                                <MenuItem value={"0h30m0s"}>30 {this.getTranslation('minutes')}</MenuItem>
                                <MenuItem value={"1h0m0s"}>1 {this.getTranslation('hour')}</MenuItem>
                                <MenuItem value={"1h30m0s"}>1h 30m</MenuItem>
                                <MenuItem value={"2h0m0s"}>2 {this.getTranslation('hours')}</MenuItem>
                                <MenuItem value={"2h30m0s"}>2h 30m</MenuItem>
                                <MenuItem value={"3h0m0s"}>3 {this.getTranslation('hours')}</MenuItem>
                                <MenuItem value={"3h30m0s"}>3h 30m</MenuItem>
                                <MenuItem value={"4h0m0s"}>4 {this.getTranslation('hours')}</MenuItem>
                                <MenuItem value={"5h0m0s"}>5 {this.getTranslation('hours')}</MenuItem>
                                <MenuItem value={"6h0m0s"}>6 {this.getTranslation('hours')}</MenuItem>
                                <MenuItem value={"1d"}>1 {this.getTranslation('day')}</MenuItem>
                                <MenuItem value={"2d"}>2 {this.getTranslation('days')}</MenuItem>
                                <MenuItem value={"more"}>{this.getTranslation('more')}...</MenuItem>
                            </Field>
                        </FormControl>
                    </Grid>
                    <h3>{this.getTranslation('customer')}</h3>
                    <Grid item className="appointment-form-customer">
                        <FormControl className="form-input-margin-right">
                            <Field name="customerId" component={renderReactSelectField}
                                id="customerId"
                                margin="none"
                                fullWidth="false"
                                className="appointment-form-informations-customer form-input-width-250"
                                placeholder={this.getTranslation('selectACustomer')}
                                onChange={this.onCustomerChange}
                                options={this.props.customerOptions}
                            />
                        </FormControl>

                        <FormControl className="form-input-margin-right">
                            <InputLabel htmlFor="vehicle">
                                {this.getTranslation('vehicle')}
                            </InputLabel>
                            <Field name="vehicle" component={renderSelectField}
                                id="vehicle"
                                margin="none"
                                fullWidth="false"
                                className="form-input-width-250"
                            >
                                {this.renderVehicleItems()}
                            </Field>
                        </FormControl>
                        {this.props.vehicle && <Field className="form-input-width-150"
                            name='vehicle.distance' component={renderTextField}
                            label={this.getTranslation('distance')}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="start">Km</InputAdornment>,
                            }}
                        />}
                    </Grid>

                    <FieldArray name="repairs" component={this.renderRepairs} />
                </Grid>
                <Container className="appointment-form-action">
                    <Button className="appointment-form-save" type="submit" disabled={!this.props.valid}>
                        <Icon fontSize="large">save</Icon>
                        <Typography variant="body1">{this.getTranslation('save')}</Typography>
                    </Button>
                </Container>
            </form>
        );
    }
}

const validate = formValues => {
    const errors = {};

    if (!formValues.duration) {
        errors.duration = 'Duration is required.';
    }
    if (!formValues.customerId) {
        errors.customerId = 'Customer is required.';
    }

    if (!formValues.vehicle) {
        errors.vehicle = 'vehicle is required.';
    }
    if (formValues.repairs) {
        errors.repairs = [];
        for (var i = 0; i < formValues.repairs.length; i++) {
            if (formValues.repairs[i].id === 0) {
                errors.repairs[i] = { id: 'The repair is irequired.' };
            }
        }
    }
    return errors;
}

AppointmentForm = reduxForm({
    form: 'appointmentForm',
    validate
})(AppointmentForm);

const selector = formValueSelector('appointmentForm');

const mapStateToProps = (state, ownProps) => {
    const { date, duration, customerId, vehicle, repairs } = selector(state, 'date', 'duration', 'customerId', 'vehicle', 'repairs');
    
    const customerOptions = ownProps.customers.map(customer => {
        return { value: customer.id, label: `${customer.lastName.toUpperCase()} ${customer.firstName ? customer.firstName : ''}` };
    });

    if (customerId && !(typeof (customerId) === "string") && !customerId.label && customerId.value) {
        let option = customerOptions.find(option => option.value === customerId.value);
        customerId.label = option && option.label ? option.label : '';
    }

    return {
        date,
        duration,
        customerId,
        vehicle,
        repairs,
        customerOptions
    }
};

AppointmentForm = connect(mapStateToProps)(withTranslation()(AppointmentForm));

export default AppointmentForm;
