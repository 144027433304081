import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { TextField, InputAdornment, Icon, Grid, Typography } from '@material-ui/core';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Header from '../shared/Header';
import Main from '../shared/Main';
import VehicleCustomerInfos from './VehicleCustomerInfos';
import VehicleAppointmentInfos from './VehicleAppointmentInfos';
import VehicleInfos from './VehicleInfos';
import { getCustomerByVehicleNumberPlate } from '../../actions/customerActions';
import { fetchAppointmentsByVehicleNumberPlate } from '../../actions/appointmentActions';
import { getCleanedNumberPlate } from '../shared/utils';

import './styles/Vehicle.scss';

const Vehicle = ({ customers, appointments, getCustomerByVehicleNumberPlate, fetchAppointmentsByVehicleNumberPlate }) => {
    const { numberPlateParams } = useParams();
    const [searchParams, setSearchParams] = useState({
        searching: false,
        numberPlate: numberPlateParams ? getCleanedNumberPlate(numberPlateParams) : ''
    });
    const [searchResult, setSearchResult] = useState({ searching: false, customer: undefined, appointments: undefined, vehicle: undefined });
    const { t } = useTranslation();
    const isMobile = window.screen.width <= 600

    useEffect(() => {
        if (searchResult.searching) {
            appointments.sort(function (a, b) {
                return new Date(b.date) - new Date(a.date);
            });

            setSearchResult({
                searching: searchResult.searching,
                customer: searchResult.customer,
                appointments,
                vehicle: searchResult.vehicle ? searchResult.vehicle : appointments && appointments.length > 0 ? appointments[0].vehicle : null
            });
        }
    }, [appointments, searchResult.searching, searchResult.customer, searchResult.vehicle, searchParams.numberPlate])

    const launchSearch = async () => {
        await fetchAppointmentsByVehicleNumberPlate(searchParams.numberPlate);
        await getCustomerByVehicleNumberPlate(searchParams.numberPlate);
        let vehicleIndex = -1;
        const customerIndex = _.findIndex(customers, c => {
            vehicleIndex = _.findIndex(c.vehicles, { 'numberPlate': searchParams.numberPlate })
            if (vehicleIndex === -1) {
                vehicleIndex = _.findIndex(c.vehicles, { 'oldNumberPlate': searchParams.numberPlate })
            }

            return vehicleIndex !== -1
        })
        const tmpCustomer = customerIndex === -1 ? null : customers[customerIndex]
        setSearchParams({
            searching: true,
            numberPlate: searchParams.numberPlate
        })

        setSearchResult({
            searching: true,
            customer: tmpCustomer,
            appointments: null,
            vehicle: tmpCustomer ? tmpCustomer.vehicles[vehicleIndex] : null
        });
    }

    const handleKeyPress = async (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (searchParams.numberPlate) {
                await launchSearch()
            } else {
                setSearchResult({ searching: false, customer: null, appointments: null, vehicle: null });
            }
        }
    }

    return (

        <Main header={
            <Header titleLinkTo='/vehicles' titleLinkClick={undefined} titleLinkContent={t('vehicle.title')}
                searchField={
                        <Grid container spacing={2}>
                            <Grid item>
                                <TextField
                                    className="vehicle-component-searchfield"
                                    type="search"
                                    name="search-plateNumber"
                                    placeholder={t('vehicle.numberPlate')}
                                    value={searchParams.numberPlate}
                                    onChange={e => setSearchParams({ searching: false, numberPlate: getCleanedNumberPlate(e.target.value) })}
                                    autoFocus
                                    autoComplete="off"
                                    onKeyPress={handleKeyPress}
                                    InputProps={{
                                        startAdornment:
                                            <InputAdornment position="start">
                                                <Icon className="search-icon">search</Icon>
                                            </InputAdornment>,
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <Icon className={`header-search-icon ${searchParams.numberPlate ? '' : 'disabled'}`}
                                    onClick={async () => {
                                        if (searchParams.numberPlate) {
                                            await launchSearch()
                                        }
                                    }}>
                                    search
                                </Icon>
                            </Grid>
                        </Grid>
                }
                hasAction={false}
            />
        }>
            <Grid container className="vehicle-component">
                {searchParams.searching && ((searchResult.appointments && searchResult.appointments.length > 0) || searchResult.customer) &&
                    <React.Fragment>
                        <Grid item>
                            <Grid className="vehicle-item-customer-container" container direction="column">
                                <Grid item className="vehicle-item-customer-infos">
                                    <VehicleCustomerInfos customer={searchResult.customer} />
                                </Grid>
                                <Grid item className="vehicle-item-infos">
                                    <VehicleInfos vehicle={searchResult.vehicle} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container direction="column" className="vehicle-item-appointments-infos">
                                <Grid item className="vehicle-item-title">
                                    <Icon>event_notes</Icon>
                                    <Typography variant="h6">{t('vehicle.history')}</Typography>
                                </Grid>
                                <Grid container direction="column" className="vehicle-item-appointment-items">
                                    <Grid container direction="column" className="vehicle-item-appointment-items-content">
                                        <Grid item>
                                            <Grid container>
                                                <Grid item xs={isMobile ? 4 : 2}>
                                                    <Typography variant="body1">{t('vehicle.date')}</Typography>
                                                </Grid>
                                                {!isMobile && <React.Fragment>
                                                    <Grid item xs={3}>
                                                        <Typography variant="body1">{t('vehicle.owner')}</Typography>
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <Typography variant="body1">{t('vehicle.distance')}</Typography>
                                                    </Grid>
                                                </React.Fragment>}
                                                <Grid item xs={isMobile ? 8 : 6}>
                                                    <Typography variant="body1">{t('vehicle.repairs')}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {searchResult.appointments && searchResult.appointments.map(appointment => {
                                            return (
                                                <Grid item key={appointment.id}>
                                                    <VehicleAppointmentInfos appointment={appointment} owner={searchResult.customer} />
                                                </Grid>
                                            );
                                        })
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                }
                {searchParams.searching && ((!searchResult.appointments || searchResult.appointments.length === 0) && !searchResult.customer) &&
                    <Grid className="customer-no-search-item" container direction="column">
                        <Grid item>
                            <Icon>close</Icon>
                        </Grid>
                    </Grid>
                }
                {!searchParams.searching &&
                    <Grid className="customer-no-search-item" container direction="column">
                        <Grid item>
                            <Icon>search</Icon>
                        </Grid>
                    </Grid>
                }
            </Grid>
        </Main>
    );
}

const mapStateToProps = state => {
    return { customers: Object.values(state.customers), appointments: Object.values(state.appointments) }
}

export default connect(mapStateToProps, { getCustomerByVehicleNumberPlate, fetchAppointmentsByVehicleNumberPlate })(Vehicle);