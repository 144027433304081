import React from 'react';
import history from '../../history';
import { Button, Icon, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import './styles/BackLink.scss';

const BackLink = () => {
    const { t } = useTranslation();
    return (
        <Button className="back-link-component"
            onClick={() => history.goBack()}>
            <Icon>arrow_back</Icon>
            <Typography variant="subtitle1">{t('back')}</Typography>
        </Button>
    );
}

export default BackLink;