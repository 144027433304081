import React from 'react';
import { connect } from 'react-redux';
import { Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { deleteCustomer } from '../../actions/customerActions';
import ModalGen from '../shared/ModalGen';

import './styles/CustomerDelete.scss';

const CustomerDelete = ({ customer, deleteCustomer, handleClose, open }) => {

    const { t } = useTranslation();

    return (
        <ModalGen
            handleClose={handleClose}
            open={open}
            header={<Typography variant="h6">{t('customer.deleteCustomerTitle')}</Typography>}
            content={
                <Typography className="customer-delete-modal-content" variant="body1">
                    {`${t('customer.deleteCustomerQuestion')} ${customer ? `${customer.firstName} ${customer.lastName}` : ''} ?`}
                </Typography>
            }
            actions={
                <React.Fragment>
                    <Button onClick={() => {
                        deleteCustomer(customer.id);
                        handleClose();
                    }}>
                        {t('yes')}
                    </Button>
                    <Button onClick={() => handleClose()}>
                        {t('no')}
                    </Button>
                </React.Fragment>
            } />
    );
}

export default connect(null, { deleteCustomer })(CustomerDelete);