import React from 'react'
import history from '../../history'

import { getFormattedNumberPlate, getCleanedNumberPlate } from './utils'
import './styles/NumberPlate.scss'

const NumberPlate = ({ numberPlate }) => {

    const handleClick = () => {
        if(!history.location.pathname.startsWith('/vehicles')){
            history.push(`/vehicles/${getCleanedNumberPlate(numberPlate)}`)
        }
    }

    return (
        <span onClick={handleClick}
            className={`vehicle-number-plate-component ${history.location.pathname.startsWith('/vehicles') ? '' : 'clickable'}`}>
            {getFormattedNumberPlate(numberPlate)}
        </span>
    )
}

export default NumberPlate