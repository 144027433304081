import React from 'react';
import { connect } from 'react-redux';
import { createCustomer } from '../../actions/customerActions';
import CustomerForm from './CustomerForm';

import './styles/CustomerCreate.scss';
import BackLink from '../shared/BackLink';

const CustomerCreate = ({ createCustomer }) => {
    return (
        <React.Fragment>
            <BackLink />
            <CustomerForm onSubmit={formValues => createCustomer(formValues)} />
        </React.Fragment>
    );
}

export default connect(null, { createCustomer })(CustomerCreate);