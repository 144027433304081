import React from 'react';
import { Icon, Fab, Typography, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';

import './styles/Header.scss';

const Header = ({ titleLinkTo, titleLinkClick, titleLinkContent,
    searchField,
    hasAction, actionTitle, actionLinkTo, actionLinkIcon,
    className, isMobile}) => {

    return (
        <header className={className}>
            <Link to={titleLinkTo} onClick={titleLinkClick}>
                <Typography variant="overline">{titleLinkContent}</Typography>
            </Link>
            <Grid container direction="row" alignItems="baseline">
                <Grid item md={9} xs={12} className='header-search-field'>
                    {searchField}
                </Grid>

                {hasAction && <Grid item md={3} xs={2} className="header-action">
                    <Grid container alignItems="center" justify="flex-end">
                        <React.Fragment>
                            <Typography variant="caption" className="header-action-label">{actionTitle}</Typography>
                            <Link to={actionLinkTo}>
                                <Fab size="small">
                                    <Icon>{actionLinkIcon}</Icon>
                                </Fab>
                            </Link>
                        </React.Fragment>
                    </Grid>
                </Grid>}
            </Grid>
        </header>
    );
}

export default Header;