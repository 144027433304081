import _ from 'lodash'
import moment from 'moment'

export const isAHoliday = (holidays, day) => {
    return _.findIndex(holidays, h => {
        return moment(day).isSame(h.start, 'day') 
        || (h.end && 
            (moment(day).isSame(h.end, 'day')
                || moment(day).isBetween(moment(h.start), moment(h.end), 'day')))
    }) !== -1
}