import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Router, Route } from 'react-router-dom'
import { Container, Icon } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import fr from "date-fns/locale/fr"
import en from "date-fns/locale/en-GB"
import _ from 'lodash'

import './styles/App.scss'

import NavigationBar from '../navigation-bar/NavigationBar'
import Customer from '../customer/Customer'
import Appointment from '../appointment/Appointment'
import Vehicle from '../vehicle/Vehicle'
import CustomerCreate from '../customer/CustomerCreate'
import history from '../../history'
import CustomerEdit from '../customer/CustomerEdit'
import AppointmentCreate from '../appointment/AppointmentCreate'
import AppointmentEdit from '../appointment/AppointmentEdit'
import Login from '../login/Login'
import PrivateRoute from './PrivateRoute'
import { fetchCustomers } from '../../actions/customerActions'
import { getAccount } from '../../actions/accountActions'
import CustomSnackBar from '../shared/CustomSnackBar'
import { checkToken } from '../../utils/authUtils'
import { signOut } from '../../actions/authActions'
import { getLanguageNameFromId } from '../../utils/languageUtils'
import { fetchHolidays } from '../../actions/holidaysActions'

const App = ({ error, success, customers, signOut, fetchCustomers, fetchHolidays, holidays, account, getAccount }) => {
    const { t } = useTranslation()
    const isAuthenticated = checkToken()

    if (isAuthenticated === undefined) {
        signOut()
    }

    useEffect(() => {
        if (isAuthenticated) {
            fetchCustomers()
        }
    }, [isAuthenticated, fetchCustomers])

    useEffect(() => {
        if (isAuthenticated) {
            fetchHolidays()
        }
    }, [isAuthenticated, fetchHolidays])

    useEffect(() => {
        if (isAuthenticated && (!account || _.isEmpty(account))) {
            getAccount();
        }
    }, [isAuthenticated, account, getAccount])

    const language = getLanguageNameFromId(+sessionStorage.getItem('language'))

    return (
        <Router history={history}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={language === 'en' ? en : fr}>
                <React.Fragment>
                    <Container className={isAuthenticated ? 'main-container' : 'main-container-login'}>
                        <Route path="/" exact component={isAuthenticated ? Appointment : Login} />
                        {!isAuthenticated && <Route path="/login" exact component={Login} />}
                        <PrivateRoute path="/customers" exact component={Customer} />
                        <PrivateRoute path="/customers/edit/:id" exact component={CustomerEdit} />
                        <PrivateRoute path="/customers/create" exact component={CustomerCreate} />
                        <PrivateRoute path="/vehicles" exact component={Vehicle} />
                        <PrivateRoute path="/vehicles/:numberPlateParams" exact component={Vehicle} />
                        <PrivateRoute path="/appointments" exact component={Appointment} />
                        <PrivateRoute path="/appointments/date/:day/:month/:year" exact component={Appointment} />
                        <PrivateRoute path="/appointments/create" exact component={AppointmentCreate} />
                        <PrivateRoute path="/appointments/create/from/:date" exact component={AppointmentCreate} />
                        <PrivateRoute path="/appointments/create/from/:date/customer/:customerId" exact component={AppointmentCreate} />
                        <PrivateRoute path="/appointments/edit/:id" exact component={AppointmentEdit} />
                        {error &&
                            <CustomSnackBar className="snackbar-error"
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                message={<React.Fragment><Icon>error</Icon>{t(error)}</React.Fragment>} />
                        }
                        {success &&
                            <CustomSnackBar className="snackbar-success"
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                message={<React.Fragment><Icon>check_circle</Icon>{t(success)}</React.Fragment>} />
                        }
                    </Container>
                    {isAuthenticated && <NavigationBar />}
                </React.Fragment>
            </MuiPickersUtilsProvider>
        </Router>
    )
}

const mapStateToProps = ({ requestResult, customers, account, holidays }) => {
    return {
        error: requestResult.error,
        success: requestResult.success,
        customers: customers ? Object.values(customers) : null,
        account,
        holidays
    }
}

export default connect(mapStateToProps, { fetchCustomers, fetchHolidays, getAccount, signOut })(App)