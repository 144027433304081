import { GET_ACCOUNT_PROFILE, EDIT_ACCOUNT_PROFILE } from '../actions/types'

export default (state = {}, { type, payload }) => {
    switch (type) {
        case GET_ACCOUNT_PROFILE:
            return { displayName: payload.displayName, email: payload.email, language: payload.language }
        case EDIT_ACCOUNT_PROFILE:
            return { displayName: payload.displayName, email: payload.email, language: payload.language }
        default:
            return state
    }
}