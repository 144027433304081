import React from 'react';

import { TextField, Select as MaterialSelect } from '@material-ui/core';
import { KeyboardTimePicker } from '@material-ui/pickers';
import Select, { createFilter } from 'react-select';

import SharedDatePicker from './SharedDatePicker';

export const renderTextField = formProps => {
    return (
        <TextField {...formProps.input}
            id={formProps.id}
            label={formProps.label}
            margin={formProps.margin}
            fullWidth={formProps.fullWidth === 'true'}
            InputProps={formProps.InputProps}
            className={formProps.className}
            error={formProps.meta.error && formProps.meta.touched}
            hidden={formProps.hidden === true}
        />
    );
}

export const renderDatePickerField = formProps => {
    return (
        <SharedDatePicker {...formProps.input}
            {...formProps}
            fullWidth={formProps.fullWidth === 'true'}
            error={formProps.meta.error && formProps.meta.touched} />
    );
}

export const renderShareDatePickerField = formProps => {
    return (
        <SharedDatePicker {...formProps.input}
            className={formProps.className}
            label={formProps.label}
            format="dd-MM-yyyy HH:mm"
        />
    );
}

export const renderTimePickerField = formProps => {
    return (
        <KeyboardTimePicker
            format="HH:mm"
            margin={formProps.margin}
            id={formProps.id}
            label={formProps.label}
            name={formProps.name}
            value={formProps.input.value}
            onChange={formProps.onTimeChange}
            ampm={false}
            fullWidth={formProps.fullWidth}
            className={formProps.className}
        />
    );
}


export const renderSelectField = formProps => {
    return (
        <MaterialSelect {...formProps.input}
            id={formProps.id}
            label={formProps.label}
            margin={formProps.margin}
            fullWidth={formProps.fullWidth === 'true'}
            className={formProps.className}
            error={formProps.meta.error && formProps.meta.touched}
            MenuProps={{
                style: {
                    maxHeight: 415,
                    minWidth: 100,
                },
            }}
        >
            {formProps.children}
        </MaterialSelect>
    );
}

export const renderReactSelectField = formProps => {
    
    return (
        <Select {...formProps.input}
            id={formProps.id}
            fullWidth={formProps.fullWidth}
            className={formProps.className}
            margin={formProps.margin}
            options={formProps.options}
            placeholder={formProps.placeholder}
            onBlur={() => { }}
            filterOption={createFilter({
                ignoreCase: true,
                ignoreAccents: false,
                trim: true,
                matchFrom: 'start',
            })}
            maxMenuHeight={415} />

    );
}