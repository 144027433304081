export const CREATE_CUSTOMER = 'CREATE_CUSTOMER'
export const FETCH_CUSTOMERS = 'FETCH_CUSTOMER'
export const GET_CUSTOMER = 'GET_CUSTOMER'
export const EDIT_CUSTOMER = 'EDIT_CUSTOMER'
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER'
export const SEARCH_CUSTOMER_LASTNAME = 'SEARCH_CUSTOMER_LASTNAME'
export const SEARCH_CUSTOMER_NUMBERPLATE = 'SEARCH_CUSTOMER_NUMBERPLATE'

export const CREATE_APPOINTMENT = 'CREATE_APPOINTMENT'
export const FETCH_APPOINTMENTS = 'FETCH_APPOINTMENT'
export const GET_APPOINTMENT = 'GET_APPOINTMENT'
export const EDIT_APPOINTMENT = 'EDIT_APPOINTMENT'
export const DELETE_APPOINTMENT = 'DELETE_APPOINTMENT'

export const GET_ACCOUNT_PROFILE = 'GET_ACCOUNT_PROFILE'
export const EDIT_ACCOUNT_PROFILE = 'EDIT_ACCOUNT_PROFILE'

export const GET_HOLIDAYS = "GET_HOLIDAYS"
export const CREATE_HOLIDAYS = "CREATE_HOLIDAYS"
export const DELETE_HOLIDAYS = "DELETE_HOLIDAYS"

export const SIGNIN = "SIGNIN"
export const SIGNOUT = "SIGNOUT"

export const SUCCESS = "SUCCESS"
export const ERROR = "ERROR"
