import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Typography, Grid, Chip } from '@material-ui/core';
import moment from 'moment';

import { getCustomer } from '../../actions/customerActions';

import './styles/VehicleAppointmentInfos.css'

const VehicleAppointmentInfos = ({ appointment, owner, customer, getCustomer }) => {

    const isMobile = window.screen.width <= 600

    useEffect(() => {
        if (!customer) {
            getCustomer(appointment.customerId);
        }
    }, [getCustomer, appointment, customer])

    return (
        <Grid container alignItems="center">
            <Grid item xs={isMobile ? 4 : 2}>
                <Typography variant="body2">{moment(appointment.date).format("DD/MM/YYYY")}</Typography>
            </Grid>
            {!isMobile && <React.Fragment>
                <Grid item xs={3}>
                    {customer && <Typography variant="body2">{customer.lastName} {customer.firstName ? customer.firstName.substring(0, 1) : 'M'}.</Typography>}
                </Grid>
                <Grid item xs={1}>
                    <Typography variant="body2">{appointment.vehicle.distance}</Typography>
                </Grid>
            </React.Fragment>}
            <Grid item xs={isMobile ? 8 : 6}>
                {appointment.repairs && appointment.repairs.map(repair => {
                    return (
                        <Chip key={repair.name}
                            className="vehicle-appointment-infos-repairs"
                            label={repair.name}
                            title={repair.comment}
                        />
                    );
                })}
            </Grid>
        </Grid>
    );
};

const mapStateToProps = ({ customers }, ownProps) => {
    return { customer: customers[ownProps.appointment.customerId] }
}
export default connect(mapStateToProps, { getCustomer })(VehicleAppointmentInfos);