import { SIGNIN, SIGNOUT } from '../actions/types'

export default (state = {}, action) => {
    switch (action.type) {
        case SIGNIN:
            {
                return {
                    token: action.payload.access_token,
                    refresh: action.payload.refresh_token
                }
            }
        case SIGNOUT:
            return { token: null, refresh: null }
        default:
            return state
    }
}