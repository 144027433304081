import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Grid, Typography, Button, Icon, Badge, Link } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import { fetchAppointmentsByDateRange } from '../../actions/appointmentActions'
import CalendarItem from './CalendarWeekItem'
import CalendarItemDetails from './CalendarWeekItemDetails'
import history from '../../history'
import ShowMoreAppointmentsModal from './ShowMoreAppointmentsModal'
import { getFormattedNumberPlate } from '../shared/utils'
import { isAHoliday } from '../../utils/holidaysUtils'

import './styles/CalendarWeek.scss'

const getMondayFromDate = date => {
    let nbDaysToMonday = date.getDay() === 0 ? -6 : 1 - date.getDay()
    date.setHours(0, 0, 0, 0)
    return new Date(date.setDate(date.getDate() + nbDaysToMonday))
}

const CalendarWeek = ({ fetchAppointmentsByDateRange,
    date, appointments, customers, nbDaysPerWeek, holidays,
    onDeleteAppointment, onCalendarDayClick
}) => {
    const { t } = useTranslation()
    const [firstDay, setFirstDay] = useState(getMondayFromDate(new Date(date)))
    const [itemDetails, setItemDetails] = useState({ open: false, itemClicked: false, anchorEl: null, title: '', content: null, actions: null })
    const [showMoreItems, setShowMoreItems] = useState({ open: false, appointments: null })

    const getItemDetailsTitleFromData = (appointment) => {
        return `${t('appointment.at')} ${moment(appointment.date).format('HH:mm')}`
    }

    const getItemDetailsContentFromData = (appointment, customer) => {
        return (
            <React.Fragment>
                <Grid item>
                    <Typography variant="h6">{t('appointment.contacts')}</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body2">{t('appointment.phones')}:</Typography>
                    <ul className="calendar-week-item-content-phones">
                        {customer.phones.map(phone => <li key={phone}><Typography variant="body2">{phone}</Typography></li>)}
                    </ul>
                </Grid>
                <Grid item>
                    <Typography variant="h6">{t('appointment.vehicle')}</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body2">{t('appointment.numberPlate')}: {getFormattedNumberPlate(appointment.vehicle.numberPlate)}</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body2">{t('appointment.brand')}: {appointment.vehicle.brand}</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body2">{t('appointment.model')}: {appointment.vehicle.model} ({appointment.vehicle.year})</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body2">{t('appointment.distance')}: {appointment.vehicle.distance} Km</Typography>
                </Grid>
                <Grid item>
                    <Badge className="calendar-week-item-content-badge" badgeContent={appointment.repairs ? appointment.repairs.length : "0"}>
                        <Typography variant="h6">{t('appointment.repairs')}</Typography>
                    </Badge>
                </Grid>
                {appointment.repairs && appointment.repairs.map((repair, index) => {
                    return (
                        <Grid item key={appointment.id + index}>
                            <Typography variant="body2">{repair.name} {repair.comment ? `(${repair.comment})` : ''}</Typography>
                        </Grid>
                    )
                })
                }
            </React.Fragment>
        )
    }

    const getItemDetailsActionsFromData = (appointment) => {
        return (
            <React.Fragment>
                <Button className="item-details-actions" onClick={() => history.push(`/appointments/edit/${appointment.id}`)}>
                    <Icon fontSize="small">edit</Icon>
                </Button>
                <Button className="item-details-actions" onClick={() => onDeleteAppointment(appointment.id)}>
                    <Icon fontSize="small">delete</Icon>
                </Button>
            </React.Fragment>
        )
    }

    const showItemDetails = (event, appointment, customer) => {
        if (!itemDetails.itemClicked && appointment && customer) {
            setItemDetails(
                {
                    anchorEl: event.currentTarget,
                    title: getItemDetailsTitleFromData(appointment),
                    content: getItemDetailsContentFromData(appointment, customer),
                    actions: getItemDetailsActionsFromData(appointment),
                    open: true,
                }
            )
        }
    }

    const hideItemDetails = () => {
        if (!itemDetails.itemClicked) {
            setItemDetails({ open: false, anchorEl: null })
        }
    }

    const toggleItemDetails = (event, appointment, customer) => {
        if (event.type === 'blur') {
            if (event.relatedTarget && event.relatedTarget.className.includes('item-details-actions')) {
                event.relatedTarget.click()
            }
            setItemDetails({ open: false, anchorEl: null })
        } else {
            if (!itemDetails.itemClicked) {
                setItemDetails(
                    {
                        open: true,
                        anchorEl: event.currentTarget,
                        title: getItemDetailsTitleFromData(appointment),
                        content: getItemDetailsContentFromData(appointment, customer),
                        actions: getItemDetailsActionsFromData(appointment),
                        itemClicked: !itemDetails.itemClicked,
                    })
            } else {
                setItemDetails(
                    {
                        itemClicked: !itemDetails.itemClicked
                    })
            }
        }
    }

    useEffect(() => {
        const firstDay = getMondayFromDate(new Date(date))
        let lastDay = new Date(firstDay)
        lastDay.setHours(23, 59, 59, 0)
        lastDay.setDate(lastDay.getDate() + nbDaysPerWeek - 1)

        fetchAppointmentsByDateRange(firstDay, lastDay)
        setFirstDay(firstDay)
    }, [date, setFirstDay, fetchAppointmentsByDateRange, nbDaysPerWeek])


    let appointmentPerDay = [...Array(nbDaysPerWeek).keys()].map((day) => {
        let currentDate = new Date(new Date(firstDay).setDate(firstDay.getDate() + day))
        return appointments.filter(appointment => {
            return appointment.date.getDay() === currentDate.getDay()
        })
    })

    const renderCalendarDayItem = (day, currentDate, isToday, isHoliday) => {
        return (
            <Grid key={`calendar-week-day-item-${day}`} item xs={4}
                className={`calendar-week-day-item calendar-week-day-item-${nbDaysPerWeek}-days 
                ${isToday ? 'calendar-week-today-item' : ''} 
                ${isHoliday ? 'calendar-week-holiday-item' : ''}`}>
                <Grid container direction="column">
                    {[...Array(12).keys()].map((hour) => {
                        const filteredAppointmentsOnHour = appointmentPerDay[day].filter(appointment => {
                            return appointment.date.getHours() === (hour + 7)
                        })

                        filteredAppointmentsOnHour.sort(function (a, b) {
                            return new Date(a.date) - new Date(b.date)
                        })

                        return (
                            <Grid key={hour + 7} item className="calendar-week-day-hour-item calendar-week-principal-content-height"
                                onDoubleClick={(e) => {
                                    if (e.target.className.includes('calendar-week-day-hour-item')) {
                                        history
                                            .push(`/appointments/create/from/${moment(currentDate).add(hour + 7, 'hours').toISOString()}`)
                                    }
                                }}
                            >
                                {filteredAppointmentsOnHour.map((appointment, index) => {
                                    if (index < 3) {
                                        let customer = customers[appointment.customerId]
                                        let label = `${appointment.vehicle.brand} ${appointment.vehicle.model}`
                                        if (customer) {
                                            label = `${customer.lastName} ${customer.firstName} - ${label}`
                                        }
                                        return <CalendarItem key={appointment.id} label={`${label}`}
                                            nbDaysPerWeek={nbDaysPerWeek}
                                            onDelete={() => onDeleteAppointment(appointment.id)}
                                            onClick={(e) => toggleItemDetails(e, appointment, customer)}
                                            onDoubleClick={() => { history.push(`/appointments/edit/${appointment.id}`) }}
                                            onBlur={(e) => toggleItemDetails(e)}
                                            onMouseOver={(e) => showItemDetails(e, appointment, customer)}
                                            onMouseLeave={(e) => hideItemDetails(e)} />
                                    } else if (index === 3) {
                                        return <Link key={appointment.id}
                                            className="calendar-week-item-view-more"
                                            onClick={() => {
                                                setShowMoreItems({
                                                    open: !showItemDetails.open,
                                                    appointments: filteredAppointmentsOnHour,
                                                })
                                            }}
                                        ><Typography variant="body2">{t('appointment.viewMore')}</Typography></Link>
                                    }
                                    return <React.Fragment></React.Fragment>
                                })}
                            </Grid>
                        )
                    })}
                </Grid>
            </Grid>
        )
    }

    const now = new Date(new Date().setHours(0, 0, 0, 0)).getTime()
    return (
        <React.Fragment>
            <Grid container spacing={0} className="calendar-week-component">
                <Grid item className="calendar-week-component-header" xs={12}>
                    <Grid container>
                        <Grid item className="calendar-week-header-day calendar-week-header-day-empty"><p></p></Grid>
                        {[...Array(nbDaysPerWeek).keys()].map((day) => {
                            const currentDate = new Date(new Date(firstDay).setDate(firstDay.getDate() + day))
                            const isToday = now === currentDate.getTime()
                            return <Grid key={`calendar-week-header-day-${day}`} item
                                className={`calendar-week-header-day ${currentDate.getTime() === now ? 'calendar-week-header-today' : ''}`}>
                                <Badge className="calendar-week-header-day-badge"
                                    badgeContent={appointmentPerDay[day].length}
                                    onClick={() => onCalendarDayClick(currentDate)}>
                                    <Typography variant={nbDaysPerWeek === 5 ? 'body1' : 'body2'}
                                        className={`${isToday ? 'calendar-week-header-day-isToday' : ''}`}>
                                        {moment(currentDate).format('ddd DD MMM YYYY')}
                                    </Typography>
                                </Badge>

                            </Grid>
                        })}
                    </Grid>
                </Grid>
                <Grid item className="calendar-week-principal-content">
                    <Grid container direction="row">
                        <Grid item xs={1} className="calendar-week-hours-principal-item">
                            <Grid container className="calendar-week-hours" direction="column">
                                {[...Array(12).keys()].map((hour) => {
                                    return (
                                        <Grid key={hour + 7} item className="calendar-week-hours-item calendar-week-principal-content-height">
                                            <Typography varient="overline">{hour + 7}h</Typography>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </Grid>
                        <Grid item xs={11} className="calendar-week-content-appointments">
                            <Grid container direction="row">
                                {
                                    [...Array(nbDaysPerWeek).keys()].map((day) => {
                                        const currentDate = new Date(new Date(firstDay).setDate(firstDay.getDate() + day))
                                        const isToday = now === currentDate.getTime()
                                        const isHoliday = isAHoliday(holidays, currentDate)

                                        return renderCalendarDayItem(day, currentDate, isToday, isHoliday)
                                    })}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {itemDetails.open &&
                <CalendarItemDetails open={itemDetails.open}
                    anchorEl={itemDetails.anchorEl}
                    title={itemDetails.title}
                    content={itemDetails.content}
                    actions={itemDetails.actions}
                />
            }
            {showMoreItems.open &&
                <ShowMoreAppointmentsModal open={showMoreItems.open}
                    appointments={showMoreItems.appointments}
                    customers={customers}
                    handleClose={() => setShowMoreItems({ open: false, appointments: null })}
                />
            }
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    return {
        appointments: Object.values(state.appointments),
        customers: state.customers,
        holidays: Object.values(state.holidays)
    }
}

export default connect(mapStateToProps, { fetchAppointmentsByDateRange })(CalendarWeek)