import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { createAppointment } from '../../actions/appointmentActions';
import { fetchCustomers } from '../../actions/customerActions';
import BackLink from '../shared/BackLink';
import AppointmentForm from './AppointmentForm';


const AppointmentCreate = ({ fetchCustomers, createAppointment, customers, date, customerId }) => {

    useEffect(() => {
        fetchCustomers();
    }, [fetchCustomers]);
    
    return (
        <React.Fragment>
            <BackLink />
            <AppointmentForm
                onSubmit={formValues => createAppointment(formValues)}
                customers={customers}
                initialValues={ {date, customerId, duration: "1h0m0s"} }
            />
        </React.Fragment>
    );
}

const mapPropsToState = (state, ownProps) => {
    const { date, customerId } = ownProps.match.params;
    return {
        customers: Object.values(state.customers),
        date: date ? new Date(date) : new Date(),
        customerId: customerId ? { value: customerId } : null 
    }
}

export default connect(mapPropsToState, { createAppointment, fetchCustomers })(AppointmentCreate);