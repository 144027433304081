import React, { useState } from 'react';
import { connect } from 'react-redux';
import { TextField, InputAdornment, Icon, Grid, CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { searchCustomerByLastname, getCustomerByVehicleNumberPlate } from '../../actions/customerActions';
import CustomerList from './CustomerList';
import Header from '../shared/Header';
import Main from '../shared/Main';

import './styles/Customer.scss';
import { getCleanedNumberPlate } from '../shared/utils';

const Customer = ({ searchCustomerByLastname, getCustomerByVehicleNumberPlate }) => {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useState({ searching: 0, lastname: '', numberPlate: '' })

    const launchSearch = async searchName => {
        if (searchName === 'search-lastname') {
            setSearchParams({ searching: 1, lastname: searchParams.lastname, numberPlate: '' })
            await searchCustomerByLastname(searchParams.lastname);
            setSearchParams({ searching: 2, lastname: searchParams.lastname, numberPlate: '' })
        } else if (searchName === 'search-number-plate') {
            setSearchParams({ searching: 1, lastname: '', numberPlate: searchParams.numberPlate })
            await getCustomerByVehicleNumberPlate(searchParams.numberPlate);
            setSearchParams({ searching: 2, lastname: '', numberPlate: searchParams.numberPlate })
        }
    }

    const handleKeyPress = async (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (!searchParams.lastname && !searchParams.numberPlate) {
                setSearchParams({ searching: 0, lastname: '', numberPlate: '' })
            } else {
                launchSearch(event.target.name)
            }
        }
    }

    const titleLinkClickHandler = () => {
        setSearchParams({ searching: 0, lastname: '', numberPlate: '' })
    }

    const renderHeader = () => {
        return <Header titleLinkTo='/customers'
            titleLinkClick={titleLinkClickHandler}
            titleLinkContent={t('customer.title')}
            searchField={renderSearchField()}
            hasAction={true} actionLinkTo='/customers/create' actionTitle={t('customer.addACustomer')} actionLinkIcon='add'
        />;
    }

    const onLastnameSearchFieldChange = ({ target }) => {
        if (!target.value) {
            setSearchParams({ searching: searchParams.searching, lastname: '', numberPlate: '' })
        } else {
            setSearchParams({ searching: searchParams.searching, lastname: target.value, numberPlate: '' })
        }
    }

    const onNumberPlateSearchFieldChange = ({ target }) => {
        if (!target.value) {
            setSearchParams({ searching: searchParams.searching, lastname: '', numberPlate: '' })
        } else {
            setSearchParams({ searching: searchParams.searching, lastname: '', numberPlate: getCleanedNumberPlate(target.value) })
        }
    }

    const renderSearchField = () => {
        return (
            <Grid container spacing={1}>
                <Grid item md={6} xs={6}>
                    <TextField
                        className="customer-component-searchfield"
                        type="search"
                        name="search-lastname"
                        placeholder={t('customer.customerName')}
                        autoFocus
                        autoComplete="off"
                        onKeyPress={handleKeyPress}
                        value={searchParams.lastname}
                        onChange={onLastnameSearchFieldChange}
                        InputProps={{
                            startAdornment:
                                <InputAdornment position="start">
                                    <Icon className="search-icon">person</Icon>
                                </InputAdornment>,
                        }}
                        helperText={t('customer.inputInstrcution')}
                    />
                </Grid>
                <Grid item md={5} xs={4}>
                    <TextField
                        className="customer-component-searchfield"
                        type="search"
                        name="search-number-plate"
                        placeholder={t('customer.numberPlate')}
                        autoComplete="off"
                        onKeyPress={handleKeyPress}
                        value={searchParams.numberPlate}
                        onChange={onNumberPlateSearchFieldChange}
                        InputProps={{
                            startAdornment:
                                <InputAdornment position="start">
                                    <Icon className="search-icon">drive_eta</Icon>
                                </InputAdornment>,
                        }}
                        helperText={t('customer.inputInstrcution')}
                    />
                </Grid>
                <Grid item md={1} xs={2}>
                <Icon className={`header-search-icon ${searchParams.lastname || searchParams.numberPlate ? '' : 'disabled'}`}
                        onClick={() => launchSearch(
                            searchParams.lastname ? 'search-lastname' :  searchParams.numberPlate ? 'search-number-plate' : 'na')}>
                        search
                    </Icon>
                </Grid>
            </Grid>
        );
    }

    return (
        <Main header={renderHeader()}>
            {searchParams.searching === 0 &&
                <Grid className="customer-no-search-item" container direction="column">
                    <Grid item>
                        <Icon>search</Icon>
                    </Grid>
                </Grid>
            }
            {searchParams.searching === 1 &&
                <Grid className="customer-circular-progress" container direction="column">
                    <Grid item>
                        <CircularProgress />
                    </Grid>
                </Grid>
            }
            {searchParams.searching === 2 &&
                <CustomerList />
            }
        </Main >
    );
}

export default connect(null, { searchCustomerByLastname, getCustomerByVehicleNumberPlate })(Customer);