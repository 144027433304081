import React from 'react';
import { Chip } from '@material-ui/core';

import './styles/CalendarWeekItem.scss';

const CalendarWeekItem = ({ label, nbDaysPerWeek, onDelete, onMouseOver, onMouseLeave, onClick, onDoubleClick, onBlur }) => {
    return (
        <Chip className={`calendar-week-item calendar-week-item-${nbDaysPerWeek}-days`} variant="outlined" size="small" 
            onDelete={onDelete}
            onClick={onClick}
            onDoubleClick={onDoubleClick}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
            onBlur={onBlur}
            label={label} />
    );
}

export default CalendarWeekItem;