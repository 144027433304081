import React from 'react';
import { connect } from 'react-redux';
import { Grid, Typography, Button, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Badge, Icon } from '@material-ui/core';
import moment from 'moment';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { deleteAppointment } from '../../actions/appointmentActions';
import './styles/ShowMoreAppointmentsModal.scss';
import history from '../../history';
import ModalGen from '../shared/ModalGen';

const ShowMoreAppointmentsModal = ({ appointments, customers, open, handleClose, deleteAppointment }) => {

    const { t } = useTranslation();
    const [expanded, setExpanded] = React.useState(null);

    const handleChange = panel => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const renderHeader = () => {
        if (appointments) {
            return (
                <Typography variant="h6">
                    {`${moment(appointments[0].date).format('dddd DD MMMM YYYY - HH:00')}`}
                </Typography>
            );
        }
    }

    const renderCustomerInformation = customer => {
        return (
            <React.Fragment>
                <Typography variant="h6">{t('appointment.customer')}</Typography>
                <Typography variant="body2">{customer.lastName} {customer.firstName}</Typography>
                <Typography variant="body2">{customer.address.street}</Typography>
                <Typography variant="body2">{customer.address.zipCode} {customer.address.city}</Typography>
                <Typography variant="body2">{t('appointment.phones')}:</Typography>
                <ul className="calendar-show-more-items-list">
                    {customer.phones.map(phone => {
                        return <li key={phone}><Typography variant="body2">{phone}</Typography></li>
                    })}
                </ul>
                <Typography variant="body2">Email:</Typography>
                <ul className="calendar-show-more-items-list">
                    <li><Typography variant="body2">{customer.email}</Typography></li>
                </ul>
            </React.Fragment>
        );
    }

    const renderVehicle = appointment => {
        return (
            <React.Fragment>
                <Grid item>
                    <Typography variant="h6">{t('appointment.vehicle')}</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body2">{t('appointment.numberPlate')} {appointment.vehicle.numberPlate}</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body2">{t('appointment.brand')}: {appointment.vehicle.brand}</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body2">{t('appointment.model')}: {appointment.vehicle.model} ({appointment.vehicle.year})</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body2">{t('appointment.distance')}: {appointment.vehicle.distance} Km</Typography>
                </Grid>
            </React.Fragment>
        )
    }

    const renderAppointments = () => {
        if (appointments) {
            return appointments.map(appointment => {
                let customer = customers[appointment.customerId];
                let label = `${appointment.vehicle.brand} ${appointment.vehicle.model}`;
                if (customer) {
                    label = `${customer.lastName} ${customer.firstName} - ${label}`;
                }
                label = `${moment(appointment.date).format('HH:mm')} - ${label}`;
                return (
                    <ExpansionPanel className="show-more-appointments-expansion" key={appointment.id} square expanded={expanded === appointment.id} onChange={handleChange(appointment.id)}>
                        <ExpansionPanelSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<Icon>expand_more</Icon>}>
                            <Typography>{label}</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Grid container direction="column">
                                <Grid item>
                                    <Grid container direction="row">
                                        <Grid item xs={4}>
                                            {renderCustomerInformation(customer)}
                                        </Grid>

                                        <Grid item xs={4}>
                                            {renderVehicle(appointment)}
                                        </Grid>

                                        <Grid item xs={4}>
                                            <Grid item>
                                                <Badge badgeContent={appointment.repairs ? appointment.repairs.length : "0"} className="calendar-show-more-items-repair-badge">
                                                    <Typography variant="h6">{t('appointment.repairs')}</Typography>
                                                </Badge>
                                            </Grid>
                                            {appointment.repairs && appointment.repairs.map((repair, index) => {
                                                return (
                                                    <Grid item key={appointment.id + index}>
                                                        <Typography variant="body2">{repair.name} ({repair.comment ? repair.comment : 'no comment'})</Typography>
                                                    </Grid>
                                                );
                                            })
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid item className="show-more-appointments-actions">
                                        <Button onClick={() => {
                                            console.log('edit');
                                            history.push(`/appointments/edit/${appointment.id}`);
                                        }}>
                                            <Icon fontSize="small">edit</Icon>
                                            <Typography variant="body2">{t('edit')}</Typography>
                                        </Button>
                                        <Button onClick={() => {
                                            deleteAppointment(appointment.id);
                                            appointments = _.remove(appointments, a => a.id === appointment.id);
                                        }}>
                                            <Icon fontSize="small">delete</Icon>
                                            <Typography variant="body2">{t('delete')}</Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                );
            });
        }
    }


    return (
        <ModalGen
            handleClose={handleClose}
            open={open}
            header={renderHeader()}
            content={renderAppointments()}
            actions={<Button onClick={handleClose}>{t('close')}</Button>} />
    );
}


export default connect(null, { deleteAppointment })(ShowMoreAppointmentsModal);