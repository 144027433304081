export const getFormattedNumberPlate = numberPlate => {
    if (numberPlate) {
        if (numberPlate.length === 7) {
            return `${numberPlate.slice(0, 2)}-${numberPlate.slice(2, 5)}-${numberPlate.slice(5, 7)}`
        } else {
            return numberPlate
        }
    }
    return numberPlate
}

export const getCleanedNumberPlate = numberPlate => {
    return numberPlate.replace(" ", "").replace(/-/g, "").toUpperCase()
}

export const checkEmail = email => {
    return /^(.+@.+\..+)$/.test(email)
}

export const checkPassword = password => {
    return containsUpperCase(password) && containsDigit(password) && checkMinLength(password, 8)
}

export const containsUpperCase = word => {
    return /^(.*[A-Z]+.*)$/.test(word) 
}

export const containsDigit = word => {
    return /^(.*[0-9]+.*)$/.test(word)
}

export const checkMinLength = (word, minLength = 8) => {
    return RegExp(`^(.{${minLength},})$`).test(word)
}