import React, { useState } from 'react';
import { Snackbar, SnackbarContent, IconButton, Icon } from '@material-ui/core';

import './styles/CustomSnackBar.scss'

const CustomSnackBar = ({ className, message, anchorOrigin }) => {

    const [open, setOpen] = useState(true);

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <Snackbar
            anchorOrigin={anchorOrigin}
            open={open}
            autoHideDuration={5000}
            onClose={handleClose}
        >
            <SnackbarContent
                className={className}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className="snackbar-message">
                        {message}
                    </span>
                }
                action={[
                    <IconButton key="close" aria-label="close" color="inherit" onClick={handleClose}>
                        <Icon>close</Icon>
                    </IconButton>,
                ]}
            />
        </Snackbar>
    )
}

export default CustomSnackBar;