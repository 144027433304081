import React from 'react';
import { Container, Divider } from '@material-ui/core';

import './styles/Main.scss';

const Header = ({ header, children }) => {
    return (
        <Container className="main-component">
            {header}
            <Divider />
            <Container className="main-component-content">
                {children}
            </Container>
        </Container>
    );
}

export default Header;