import React, { useState } from 'react'
import { Popover, Icon, Container, Fab, Grid, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import './styles/CustomerItem.scss'
import CustomerDelete from './CustomerDelete'
import NumberPlate from '../shared/NumberPlate'

const CustomerItem = ({ id, lastName, firstName, address, phones, email, vehicles }) => {

    const { t } = useTranslation()
    const [customerPopover, setCustomerPopover] = useState({ open: false, anchorEl: null })
    const [customerDelete, setCustomerDelete] = useState({ open: false })

    let customer = { id, lastName, firstName, address, phones, email, vehicles }

    const isMobile = window.screen.width <= 600

    const renderVehicles = vehicles => {
        if (!vehicles || vehicles.length === 0) {
            return (
                <Container className="customer-item-no-vehicle">
                    <Typography variant="body1">{t('customer.noVehicle')}</Typography>
                </Container>
            )
        }

        return vehicles.map((vehicle, index) => {
            return (
                <Grid container key={index}>
                    <Typography variant="body1">
                        <NumberPlate numberPlate={vehicle.numberPlate} />
                        {`${vehicle.brand} ${vehicle.model} ${isMobile ? '' : `(${vehicle.distance} Km)`}`}
                    </Typography>
                </Grid>
            )
        })
    }

    if (id) {
        return (
            <React.Fragment>
                <Container className="customer-item-component">
                    <Grid className="customer-item-informations" container>
                        <Grid item xs={isMobile ? 6 : 3}>
                            <Container className="customer-item-informations-table">
                                <Icon className="customer-item-content-icon">account_box</Icon>
                                <Container>
                                    <Typography variant="body1">{lastName.toUpperCase()} {firstName}</Typography>
                                </Container>
                            </Container>
                            <Container className="customer-item-informations-table">
                                <Icon className="customer-item-content-icon">home</Icon>
                                {address && <Container>
                                    <React.Fragment>
                                        <Typography variant="body2">{address.street}</Typography>
                                        <Typography variant="body2">{address.zipCode.toUpperCase()} {address.city}</Typography>
                                    </React.Fragment>
                                </Container>}
                            </Container>
                        </Grid>
                        <Grid item xs={isMobile ? 5 : 3}>
                            <Container className="customer-item-informations-table">
                                <Icon className="customer-item-content-icon">phone</Icon>
                                <Container>
                                    {phones.map((phone, index) => {
                                        return (
                                            <Typography key={index} variant="body1">{phone}</Typography>
                                        )
                                    })}
                                </Container>
                            </Container>
                            <Container className="customer-item-informations-table">
                                <Icon className="customer-item-content-icon">email</Icon>
                                <Container>
                                    <Typography variant="body1">{email}</Typography>
                                </Container>
                            </Container>
                        </Grid>
                        {isMobile && <Grid item xs={1}>
                            <Container className="customer-item-informations-table customer-item-actions">
                                <Fab size="small" onClick={({ currentTarget }) => setCustomerPopover({ open: true, anchorEl: currentTarget })}>
                                    <Icon>more_vert</Icon>
                                </Fab>
                                <Popover
                                    open={customerPopover.open}
                                    anchorEl={customerPopover.anchorEl}
                                    onClose={() => setCustomerPopover({ open: false, anchorEl: null })}
                                    anchorOrigin={{
                                        vertical: 'center',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'center',
                                        horizontal: 'left',
                                    }}
                                >
                                    <Grid container direction="column" className="customer-item-actions-popover">
                                        <Link to={`/customers/edit/${id}`} ><Icon>edit</Icon></Link>
                                        <Link to={`/appointments/create/from/${moment(new Date().setHours(8, 0, 0, 0)).toISOString()}/customer/${id}`} ><Icon>today</Icon></Link>
                                        <Link to='' onClick={(e) => {
                                            e.preventDefault()
                                            setCustomerDelete({ open: true })
                                        }} ><Icon>delete</Icon></Link>
                                    </Grid>
                                </Popover>
                            </Container>
                        </Grid>}
                        <Grid item xs={isMobile ? 12 : 5}>
                            <Container className="customer-item-informations-table">
                                <Icon className="customer-item-content-icon">drive_eta</Icon>
                                <Container>
                                    {renderVehicles(vehicles)}
                                </Container>
                            </Container>
                        </Grid>
                        {!isMobile && <Grid item xs={1}>
                            <Container className="customer-item-informations-table customer-item-actions">
                                <Fab size="small" onClick={({ currentTarget }) => setCustomerPopover({ open: true, anchorEl: currentTarget })}>
                                    <Icon>more_vert</Icon>
                                </Fab>
                                <Popover
                                    open={customerPopover.open}
                                    anchorEl={customerPopover.anchorEl}
                                    onClose={() => setCustomerPopover({ open: false, anchorEl: null })}
                                    anchorOrigin={{
                                        vertical: 'center',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'center',
                                        horizontal: 'left',
                                    }}
                                >
                                    <Grid container direction="column" className="customer-item-actions-popover">
                                        <Link to={`/customers/edit/${id}`} ><Icon>edit</Icon></Link>
                                        <Link to={`/appointments/create/from/${moment(new Date().setHours(8, 0, 0, 0)).toISOString()}/customer/${id}`} ><Icon>today</Icon></Link>
                                        <Link to='' onClick={(e) => {
                                            e.preventDefault()
                                            setCustomerDelete({ open: true })
                                        }} ><Icon>delete</Icon></Link>
                                    </Grid>
                                </Popover>
                            </Container>
                        </Grid>}
                    </Grid>
                </Container>
                {customerDelete.open && <CustomerDelete
                    open={customerDelete.open}
                    handleClose={() => setCustomerDelete({ open: false })}
                    customer={customer}
                />}
            </React.Fragment>
        )
    } else {
        return <React.Fragment></React.Fragment>
    }
}

export default CustomerItem