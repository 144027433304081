import React from 'react';
import { connect } from 'react-redux';
import { Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { deleteAppointment } from '../../actions/appointmentActions';
import ModalGen from '../shared/ModalGen';
import './styles/AppointmentDeleteModal.scss'


const AppointmentDeleteModal = ({ appointmentId, deleteAppointment, handleClose, open }) => {
    const { t } = useTranslation();
    
    return (
        <ModalGen
            handleClose={handleClose}
            open={open}
            header={<Typography variant="h6">{t('appointment.deleteTitle')}</Typography>}
            content={<Typography className="appointment-delete-modal-content" variant="body1">{t('appointment.deleteQuestion')}</Typography>}
            actions={
                <React.Fragment>
                    <Button onClick={() => {
                        deleteAppointment(appointmentId);
                        handleClose();
                    }} >{t('yes')}</Button>
                    <Button onClick={() => handleClose()} >{t('no')}</Button>
                </React.Fragment>
            } 
            />
    );
}

export default connect(null, { deleteAppointment })(AppointmentDeleteModal);