import React, { useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Grid, Typography, Icon, Popover, Fab } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import './styles/CalendarDayItem.scss';
import NumberPlate from '../shared/NumberPlate';

const CalendarDayItem = ({ appointment, customer, onDeleteAppointment, isMobile }) => {
    const { t } = useTranslation();
    const [appointmentPopover, setAppointmentPopover] = useState({ open: false, anchorEl: null });
    console.log(isMobile)
    return (
        <React.Fragment>
            <Grid container className="calendar-day-item" spacing={0}>
                <Grid item className="calendar-day-item-actions">
                    <Fab size="small"
                        className="calendar-day-item-popover-button"
                        onClick={({ currentTarget }) => setAppointmentPopover({ open: true, anchorEl: currentTarget })}>
                        <Icon>more_vert</Icon>
                    </Fab>
                </Grid>
                <Grid item className="calendar-day-item-hour">
                    <Typography variant="h6">{moment(appointment.date).format('HH:mm')}</Typography>
                </Grid>
                {!isMobile && <Grid item>
                    <Icon>person</Icon>
                </Grid>}
                <Grid item className="calendar-day-item-customer">
                    {customer &&
                        <Grid container direction="column">
                            <Grid item>
                                <Typography variant="body2">{`${customer.lastName} ${customer.firstName}`}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="body2">{customer.address.street}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="body2">{customer.address.zipCode} {customer.address.city}</Typography>
                            </Grid>
                        </Grid>
                    }
                </Grid>
                {!isMobile && <Grid item>
                    <Icon>call</Icon>
                </Grid>}
                <Grid item className="calendar-day-item-contact">
                    {customer &&
                        <Grid container direction="column">
                            <Grid item>
                                <Typography variant="body2">{t('appointment.phones')}:</Typography>
                                <ul>
                                    {customer.phones.map(phone => {
                                        return <li key={phone}><Typography variant="body2">{phone}</Typography></li>
                                    })}
                                </ul>
                            </Grid>
                            <Grid item>
                                <Typography variant="body2">{customer.email}</Typography>
                            </Grid>
                        </Grid>
                    }
                </Grid>
                {!isMobile && <Grid item>
                    <Icon>drive_eta</Icon>
                </Grid>}
                <Grid item className="calendar-day-item-vehicle">
                    <Grid container direction={isMobile ? 'row' : 'column'} spacing={isMobile ? 1 : 0}>
                        <Grid item>
                            {/* <Typography variant="body1"> */}
                            <NumberPlate numberPlate={appointment.vehicle.numberPlate} />
                            {/* </Typography> */}
                        </Grid>
                        <Grid item>
                            <Typography variant="body2">{appointment.vehicle.brand}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2">{appointment.vehicle.model} ({appointment.vehicle.year})</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2">{appointment.vehicle.distance} km</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Icon>build</Icon>
                </Grid>
                <Grid item className="calendar-day-item-repairs">
                    <Grid container direction="column">
                        {appointment.repairs && appointment.repairs.map(repair => {
                            return (
                                <Grid item key={repair.id}>
                                    <Typography variant="body2">{repair.name} {repair.comment ? `(${repair.comment})` : ''}</Typography>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>

            </Grid>
            {appointmentPopover.open && <Popover className="calendar-day-item-popover"
                open={appointmentPopover.open}
                anchorEl={appointmentPopover.anchorEl}
                onClose={() => setAppointmentPopover({ open: false, anchorEl: null })}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
            >
                <Grid container direction="column" className="calendar-day-item-actions-popover">
                    <Link to={`/appointments/edit/${appointment.id}`} ><Icon>edit</Icon></Link>
                    <Link to='' onClick={(e) => {
                        e.preventDefault();
                        onDeleteAppointment(appointment.id);
                    }} ><Icon>delete</Icon></Link>
                </Grid>
            </Popover>}
        </React.Fragment>
    );
};

const mapPropsToState = (state, ownProps) => {
    return { customer: state.customers[ownProps.appointment.customerId] }
}

export default connect(mapPropsToState)(CalendarDayItem);

