import axios from 'axios'
import history from '../history'

const origin = window.location.origin

const api = axios.create({
    baseURL: `${origin}${origin === 'http://localhost' ? ':5010' : ''}/api`,
})

api.interceptors.request.use(function (config) {
    const token = sessionStorage.getItem("token")
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
        return config
    }
}, function (error) {
    
})

api.interceptors.response.use(function (response) {
    return response
}, function (error) {
    if (error.response.status === 401 && error.response.config.url.includes('/api/')) {
        sessionStorage.removeItem('token')
        sessionStorage.removeItem('refresh_token')
        history.push('/login')
    }
})

export default api