import React from 'react';
import { Typography, Grid, Icon } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import './styles/VehicleCustomerInfos.css';

const VehicleCustomerInfos = ({ customer }) => {
    const { t } = useTranslation();

    return (
        <Grid container
            direction="column" className="vehicle-customer-infos">
            <Grid item className="vehicle-item-title">
                <Icon>person</Icon>
                <Typography variant="h6">{t('vehicle.owner')}</Typography>
            </Grid>
            <Grid container direction="column" className="vehicle-customer-infos-content">
                {customer &&
                    <React.Fragment>
                        <Grid item>
                            <Typography variant="body1">{customer.lastName} {customer.firstName}</Typography>
                        </Grid>
                        {customer.address &&
                            <Grid item>
                                <Grid container className="vehicle-customer-infos-address" direction="column">
                                    <Grid item>
                                        <Typography variant="body2">{customer.address.street}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2">{customer.address.zipCode} {customer.address.city}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }

                        <Grid item>
                            <Grid container direction="column" className="vehicle-customer-infos-contact">
                                <Grid item >
                                    <Typography variant="body1">Contact:</Typography>
                                </Grid>
                                <Grid item>
                                    <ul>
                                        {customer.phones.map(phone => {
                                            return <li key={phone}><Typography variant="body2"><Icon>phone</Icon>{phone}</Typography></li>
                                        })}
                                    </ul>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2"><Icon>mail</Icon>{customer.email}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                }
            </Grid>
        </Grid>
    );
};

export default VehicleCustomerInfos;