export const checkToken = () => {
    const token = sessionStorage.getItem('token')
    try {
        if (token) {
            const base64Url = token.split('.')[1]
            const base64 = base64Url.replace('-', '+').replace('_', '/')
            JSON.parse(window.atob(base64))
            return true
        } else {
            return false
        }
    } catch (error) {
        return undefined
    }
}